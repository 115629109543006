const COLORS = [
    '#ff9619',
    'green',
    'purple',
    'darkgrey',
    'black',
    '#B71C1C',
    '#0D47A1',
    '#4E343E',
    '#880E47',
    '#004D40',
    '#4A148C',
    '#33691E',
    '#0388FF',
    '#1A237E',
    '#BF360C'
];
class StateManager {
    static getCategories() {
        if (StateManager._categories == undefined) {
            StateManager.loadInitialState();
        }
        const categories = StateManager._categories.sort((a, b) => {
            if (a.count > b.count)
                return 1;
            else if (a.count < b.count)
                return -1;
            else
                return 0;
        });
        for (let i = 0; i < categories.length; ++i) {
            categories[i].color = COLORS[i % COLORS.length];
        }
        return categories;
    }
}
StateManager.KEY = "com.boushar.wheel";
StateManager.CATEGORIES = "categories";
StateManager.loadInitialState = () => {
    const sState = localStorage.getItem(StateManager.KEY);
    if (sState != undefined) {
        const oState = JSON.parse(sState);
        StateManager._categories = oState;
        return;
    }
    StateManager._categories = [
        { name: "category1", count: 0, color: '' },
        { name: "category2", count: 0, color: '' },
        { name: "category3", count: 0, color: '' },
        { name: "category4", count: 0, color: '' },
        { name: "category5", count: 0, color: '' },
        { name: "category6", count: 0, color: '' }
    ];
};
StateManager.saveCategories = (categories) => {
    StateManager._categories = categories;
    localStorage.setItem(StateManager.KEY, JSON.stringify(categories));
    window.dispatchEvent(new CustomEvent("CATEGORIES_UPDATED", { detail: { categories } }));
};
StateManager.updateCount = (category) => {
    const categories = StateManager._categories.map((c, index) => {
        if (c.name == category.name) {
            c.count++;
        }
        return c;
    });
    StateManager.saveCategories(categories);
};
export default StateManager;
