import { createSlice } from "@reduxjs/toolkit";
const localWeights = localStorage.getItem("com.boushar.weight");
let initialState = {};
if (localWeights != undefined) {
    initialState = JSON.parse(localWeights, reviver);
}
const weightSlice = createSlice({
    name: "weightins",
    initialState,
    reducers: {
        enterWeightIn: (state, action) => {
            state[action.payload.weightin.date] = action.payload.weightin;
            localStorage.setItem("com.boushar.weight", JSON.stringify(selectWeightIns(state)));
            const { secretKey } = action.payload;
            if (secretKey !== undefined) {
                fetch("api/weightins", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-BOUSHAR-BARAM": secretKey
                    },
                    body: JSON.stringify(selectWeightIns(state))
                });
            }
            return state;
        },
        mergeWeightIns: (state, action) => {
            const left = selectWeightIns(state);
            const right = action.payload;
            const aLeft = Object.keys(left);
            for (let i = 0; i < aLeft.length; ++i) {
                if (right[aLeft[i]] != null) {
                    const rightWeightIn = right[aLeft[i]];
                    if (left[aLeft[i]].timeUpdated < rightWeightIn.timeUpdated) {
                        state[aLeft[i]] = rightWeightIn;
                    }
                    right[aLeft[i]] = null;
                }
            }
            const aRight = Object.keys(right);
            for (let i = 0; i < aRight.length; ++i) {
                if (right[aRight[i]] != null) {
                    state[aRight[i]] = right[aRight[i]];
                }
            }
            return state;
        }
    }
});
const selectWeightIns = function (state) {
    return state;
};
export const { enterWeightIn, mergeWeightIns } = weightSlice.actions;
export default weightSlice;
/////////////////////////////////////////////////////////////////////////
function replacer(key, value) {
    if (value instanceof Map) {
        return {
            dataType: 'Map',
            value: Array.from(value.entries()), // or with spread: value: [...value]
        };
    }
    else {
        return value;
    }
}
function reviver(key, value) {
    if (typeof value === 'object' && value !== null) {
        if (value.dataType === 'Map') {
            return new Map(value.value);
        }
    }
    return value;
}
