export class WeightIn {
    constructor(date, weight, timeUpdated = new Date().getTime()) {
        this.date = date;
        this.weight = weight;
        this.timeUpdated = new Date().getTime();
    }
    getDate() {
        return this.date;
    }
    getWeight() {
        return this.weight;
    }
    toObject() {
        return {
            date: this.date,
            weight: this.weight,
            timeUpdated: this.timeUpdated
        };
    }
    serialize() {
        return JSON.stringify(this.toObject());
    }
    static fromSerialized(serialized) {
        const w = JSON.parse(serialized);
        return new WeightIn(w.date, w.weight, w.timeUpdated);
    }
}
