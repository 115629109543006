import React, { useEffect, useState } from 'react';
import Categories from './Categories';
import StateManager from './StateManager';
import Card from '@mui/material/Card';
// TODO:
// kick off the random selection
// start by selecting between the last 2 elements of the array
// set the click to do nothing when selection is happening
// when reaching one element in the array, then we are done
// Increase the count by 1 And reset the array to show all the categries again.
const cardStyle = {
    lineHeight: '100%',
    height: '100px',
    width: '100px',
    padding: '20px 10px',
    color: 'white',
    textAlign: 'center'
};
var COIN;
(function (COIN) {
    COIN[COIN["HEAD"] = 0] = "HEAD";
    COIN[COIN["TAIL"] = 1] = "TAIL";
})(COIN || (COIN = {}));
const headOrTail = () => {
    const randomNumber = Math.floor(Math.random() * 2);
    if (randomNumber === 0) {
        return COIN.HEAD;
    }
    return COIN.TAIL;
};
export const Cards = () => {
    const [categories, setCategories] = useState();
    const [bDisableClick, setDisableClick] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [cards, setCards] = useState();
    useEffect(() => {
        setCategories(StateManager.getCategories());
        window.addEventListener('keydown', (e) => handleKeyDown(e, StateManager.getCategories()));
        return () => {
            window.removeEventListener('keydown', (e) => handleKeyDown(e, StateManager.getCategories()));
        };
    }, []);
    useEffect(() => {
        let maxCount = 0;
        if (categories != undefined) {
            maxCount = Math.max(...categories.map(c => c.count));
            const cards = [];
            categories.forEach(c => {
                const times = maxCount - c.count;
                for (let i = 0; i < (times + 1); ++i) {
                    cards.push(Object.assign(Object.assign({}, c), { state: false }));
                }
            });
            setCards(cards);
        }
    }, [categories]);
    const lightUp = (idx) => {
        if (cards != undefined) {
            cards.forEach(a => a.state = false);
            cards[idx].state = true;
            setCards([...cards]);
        }
    };
    const pickSelection = (cards) => {
        const sequence = generateSequence(cards);
        console.log(sequence);
        let idx = 0;
        const timeId = setInterval(() => {
            lightUp(sequence[idx]);
            idx++;
            if (idx >= sequence.length) {
                clearInterval(timeId);
                setDisableClick(false);
                const name = cards[sequence[idx - 1]].name;
                categories === null || categories === void 0 ? void 0 : categories.forEach(c => {
                    if (c.name === name) {
                        setSelectedCategory(c);
                        StateManager.updateCount(c);
                        setCategories([...StateManager.getCategories()]);
                    }
                });
                console.log(cards[sequence[idx - 1]].name);
            }
        }, 500);
        // setTimeout(() => {
        //    const length = categories.length
        //    if (length === 1) {
        //       setSelectedCategory(categories[0])
        //       StateManager.updateCount(categories[0])
        //       setCategories([...StateManager.getCategories()])
        //       setDisableClick(false)
        //    } else {
        //       let newCategories = categories
        //       if (headOrTail() === COIN.HEAD) {
        //          newCategories = categories.slice(0, -1)
        //          setCategories([...newCategories])
        //          return pickSelection(categories.slice(0, -1))
        //       } else {
        //          newCategories = categories.filter((_, index) => (index != categories.length - 2))
        //          setCategories([...newCategories])
        //          return pickSelection(newCategories)
        //       }
        //    }
        // }, 1000)
    };
    const handleKeyDown = (e, categories) => {
        if (e.key === 'g') {
            if (!bDisableClick) {
                setDisableClick(true);
                pickSelection(cards);
            }
        }
    };
    const kickOffSelection = () => {
        if (!bDisableClick) {
            setDisableClick(true);
            pickSelection(cards);
        }
    };
    const cardsDiv = cards === null || cards === void 0 ? void 0 : cards.map((c) => {
        let style = Object.assign({}, cardStyle);
        style.backgroundColor = c.color;
        if (c.state) {
            style.border = "2px black solid";
            style.backgroundColor = 'white';
            style.color = 'black';
        }
        return (React.createElement(Card, { onClick: kickOffSelection, style: style },
            React.createElement("div", null, c.name),
            React.createElement("div", null, c.count)));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center' } }, cardsDiv),
        React.createElement("div", null, selectedCategory != null ? selectedCategory.name : null),
        React.createElement("div", { id: "cards-categories-id" },
            React.createElement(Categories, { categories: categories, setCategories: setCategories }))));
};
function generateSequence(arr) {
    const result = [];
    for (let i = 0; i < arr.length * 2; ++i) {
        let randomIndex = Math.floor(Math.random() * arr.length);
        result.push(randomIndex);
    }
    return result;
}
