import React, { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/AppContext";
import { getAllAndFutureTasks } from "../../services/GraphService/GraphService";
import { useSelector } from "react-redux";
import { SlotsBoard } from "../../features/slots/SlotsBoard";
import dateTimeUtils from "../../utils/DateTimeUtils";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
function getWidth() {
    /*return Math.max(
       document.body.scrollWidth,
       document.documentElement.scrollWidth,
       document.body.offsetWidth,
       document.documentElement.offsetWidth,
       document.documentElement.clientWidth
    )*/
    return document.documentElement.clientWidth;
}
var LIST;
(function (LIST) {
    LIST[LIST["ONE"] = 0] = "ONE";
    LIST[LIST["THREE"] = 1] = "THREE";
    LIST[LIST["FIVE"] = 2] = "FIVE";
})(LIST || (LIST = {}));
export function Plan() {
    const app = useAppContext();
    const [todos, setTodos] = useState();
    const s = useSelector((state) => (state.slots));
    const slots = SlotsBoard.createSlotsBoardFromJson(s);
    useEffect(() => {
        const getTodos = async () => {
            const todos = (await getAllAndFutureTasks(app.authProvider)).filter((t) => {
                return t.getStatus() != 'completed';
            });
            setTodos(todos);
        };
        getTodos();
    }, [slots.updated]);
    const sortedTodos = todos === null || todos === void 0 ? void 0 : todos.sort((a, b) => {
        return a.diff(b);
    });
    // create the X-Axis
    const xAxis = {};
    let max = { 'one': 0, 'three': 0, 'five': 0, 'other': 0 };
    if (sortedTodos != undefined) {
        sortedTodos.forEach((task) => {
            let category = 'other';
            if (task.getCategories().find((c) => c == 'one')) {
                category = 'one';
            }
            else if (task.getCategories().find((c) => c == 'three')) {
                category = 'three';
            }
            else if (task.getCategories().find((c) => c == 'five')) {
                category = 'five';
            }
            if (xAxis[task.getStartDateTime().format('M/D')] == undefined) {
                xAxis[task.getStartDateTime().format('M/D')] = {};
                xAxis[task.getStartDateTime().format('M/D')][category] = 1;
            }
            else {
                if (xAxis[task.getStartDateTime().format('M/D')][category] == undefined)
                    xAxis[task.getStartDateTime().format('M/D')][category] = 0;
                xAxis[task.getStartDateTime().format('M/D')][category]++;
            }
            max[category] = Math.max(xAxis[task.getStartDateTime().format('M/D')][category], max[category]);
        });
    }
    const width = Object.keys(xAxis).length;
    const height = max['one'] + max['three'] + max['five'] + max['other'] + 1;
    // create the 2 dimensions array
    const grid = [];
    for (let i = 0; i < height; ++i) {
        grid[i] = [];
        for (let j = 0; j < width; ++j) {
            grid[i][j] = { value: null, style: {} };
        }
    }
    let count = 0;
    Object.keys(xAxis).forEach(k => {
        grid[0][count] = { value: k, style: { backgroundColor: 'grey' } };
        if (dateTimeUtils(undefined).format('M/D') == k) {
            grid[0][count].style = { backgroundColor: 'cyan' };
        }
        count++;
    });
    if (sortedTodos != undefined) {
        sortedTodos.forEach((t) => {
            // find the column to add it to
            const idx = grid[0].findIndex(o => o.value == t.getStartDateTime().format('M/D'));
            if (idx == -1) {
                throw new Error("Logic Error: the timeline contains a key that does not exist");
            }
            // iterate over the column to find a null entry
            let begin = 1;
            switch (t.getCategory()) {
                case 'one':
                    break;
                case 'three':
                    begin = max['one'] + 1;
                    break;
                case 'five':
                    begin = max['one'] + max['three'] + 1;
                    break;
                default:
                    begin = max['one'] + max['three'] + max['five'] + 1;
                    break;
            }
            for (let i = begin; i < height; ++i) {
                if (grid[i][idx].value == null) {
                    let s = {};
                    if (t.getCategories().find((c) => c == 'one')) {
                        s = { backgroundColor: 'rgb(0, 71, 171)', color: 'white' };
                    }
                    else if (t.getCategory() == 'three') {
                        s = { backgroundColor: 'rgb(8, 153, 143)', color: 'white' };
                    }
                    else if (t.getCategory() == 'five') {
                        s = { backgroundColor: 'rgb(236, 151, 6)', color: 'white' };
                    }
                    grid[i][idx] = { value: t.getTitle(), style: s };
                    break;
                }
            }
        });
    }
    const header = [];
    const rows = [];
    let i = 0;
    const today = dateTimeUtils(undefined).format('M/D');
    grid.forEach(r => {
        if (i == 0) {
            r.map((c) => {
                let headerStyles = { color: 'white' };
                if (c.value === today) {
                    headerStyles = Object.assign(Object.assign({}, headerStyles), { backgroundColor: 'grey' });
                }
                else {
                    headerStyles = Object.assign(Object.assign({}, headerStyles), { backgroundColor: 'black' });
                }
                header.push(React.createElement(TableCell, { style: headerStyles }, c.value || ""));
            });
        }
        else {
            const cells = [];
            r.map((c) => {
                const cellStyle = Object.assign({}, c.style);
                cells.push(React.createElement(TableCell, { style: cellStyle }, c.value || ""));
            });
            rows.push(React.createElement(TableRow, null, cells));
        }
        i++;
    });
    return (React.createElement(React.Fragment, null, sortedTodos != null &&
        React.createElement(TableContainer, { component: Paper },
            React.createElement(Table, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null, header)),
                React.createElement(TableBody, null, rows)))));
}
function union(json1, json2) {
    // Create a new object to store the union of the two JSONs.
    let union = {};
    // Iterate over the properties of the first JSON object.
    for (const key in json1) {
        // If the property does not exist in the second JSON object, add it to the union.
        if (!json2.hasOwnProperty(key)) {
            union[key] = json1[key];
        }
    }
    // Iterate over the properties of the second JSON object.
    for (const key in json2) {
        // If the property does not exist in the first JSON object, add it to the union.
        if (!json1.hasOwnProperty(key)) {
            union[key] = json2[key];
        }
    }
    return union;
}
