import { createSlice } from "@reduxjs/toolkit";
const transientSlice = createSlice({
    name: "transient",
    initialState: {},
    reducers: {
        tickClock(state) {
            state.now = new Date();
        }
    }
});
export const { tickClock } = transientSlice.actions;
export default transientSlice.reducer;
