import { createSlice } from '@reduxjs/toolkit';
const SETTINGS_KEY = 'com.boushar.weight.settings';
const weightSettings = localStorage.getItem(SETTINGS_KEY);
let initialState = {};
if (weightSettings != undefined) {
    initialState = JSON.parse(weightSettings);
}
export var Units;
(function (Units) {
    Units["KG"] = "kg";
    Units["LB"] = "lb";
})(Units || (Units = {}));
const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        toggleKgLbSetting: (state) => {
            if (state.units == Units.KG) {
                state.units = Units.LB;
            }
            else {
                state.units = Units.KG;
            }
            localStorage.setItem(SETTINGS_KEY, JSON.stringify(state));
            return state;
        }
    }
});
export const { toggleKgLbSetting } = settingsSlice.actions;
export default settingsSlice;
