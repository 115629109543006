import { getAllAndFutureTasks } from "../services/GraphService/GraphService";
async function readQueue(type, authProvider) {
    const todos = await getAllAndFutureTasks(authProvider);
    if (todos != null) {
        const list = todos.filter((t) => {
            return t.getStatus() != 'completed' &&
                (t.getCategories().find((c) => (c === type)) != undefined);
        });
        return list;
    }
}
function loadTasksFromStorage(displayName) {
    let returnTasks = {};
    if (displayName) {
        try {
            returnTasks = JSON.parse(localStorage.getItem(`${displayName}.tasks`));
            if (returnTasks == null) {
                returnTasks = {};
            }
        }
        catch (error) {
            // just return the empty object
        }
    }
    return returnTasks;
}
export class StorageUtils {
    static async readQueueOne(authProvider) {
        return readQueue('one', authProvider);
    }
    static async readQueueThree(authProvider) {
        return readQueue('three', authProvider);
    }
    static async readQueueFive(authProvider) {
        return readQueue('five', authProvider);
    }
    static saveQueueOne(list) {
        StorageUtils.queueOne = list;
    }
    static init(store) {
        this._instance = new Storage();
        this._instance._store = store;
    }
    static getInstance() {
        if (this._instance === null || this._instance._store === null) {
            throw "Logic error: Storage is not initialized. Need to call init on it";
        }
        return this._instance;
    }
    static loadWorkedTimeFromStorage(displayName, taskId) {
        let ret = 0;
        if (displayName) {
            const tasks = loadTasksFromStorage(displayName);
            if (taskId) {
                const task = tasks[taskId];
                if (task) {
                    const n = tasks[taskId]['workedTime'];
                    ret = n;
                }
            }
        }
        return ret;
    }
    static saveWorkedTimeToStorage(workedTime, displayName, taskId) {
        if (displayName) {
            const tasks = loadTasksFromStorage(displayName);
            if (taskId) {
                tasks[taskId] = { workedTime };
                localStorage.setItem(`${displayName}.tasks`, JSON.stringify(tasks));
            }
        }
    }
}
StorageUtils._instance = null;
