import React, { useEffect, useState } from "react";
import { useAppContext } from "../hooks/AppContext";
import { GraphClient } from "../services/GraphService/GraphClient";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import IconButton from "@mui/material/IconButton";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { TaskLink } from "../onethreefive/components/TaskLink";
export default function Location() {
    const app = useAppContext();
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [bNotifyLocation, setNotifyLocation] = useState(false);
    const [open, setOpen] = useState(false);
    const [locationTodos, setLocationTodos] = useState([]);
    const [currentPosition, setCurrentPosition] = useState();
    useEffect(() => {
        const getTodos = async () => {
            var _a, _b;
            const client = new GraphClient(app.authProvider);
            const todos = await client.getTodos('defaultList');
            const position = await getCurrentPosition();
            setCurrentPosition(position);
            const currentTodos = [];
            for (let i = 0; i < todos.length; ++i) {
                // get long and lat
                const res = (_b = (_a = todos[i].getBody()) === null || _a === void 0 ? void 0 : _a.content) === null || _b === void 0 ? void 0 : _b.match(/{"latitude":[-0-9]*\.[0-9]*,"longitude":[-0-9]*\.[0-9]*}/);
                if (res != null && res[0] != null) {
                    const location = JSON.parse(res[0]);
                    if ((Math.abs(location.latitude - position.latitude) < 0.001)
                        && (Math.abs(location.longitude - position.longitude) < 0.001)) {
                        currentTodos.push(todos[i]);
                    }
                }
            }
            if (currentTodos.length > 0) {
                const inProgressTodos = currentTodos.filter(todo => (todo.getStatus() != 'completed'));
                setLocationTodos(inProgressTodos);
                if (inProgressTodos.length > 0)
                    setNotifyLocation(true);
            }
        };
        getTodos();
        const id = setInterval(getTodos, 1000 * 60 * 10);
        return () => { clearInterval(id); };
    }, []);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const todosList = locationTodos.map((todo) => {
        var _a;
        return (React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(TaskLink, { taskId: todo.getId().taskId }),
                todo.getTitle(),
                " ", (_a = todo.getBody()) === null || _a === void 0 ? void 0 :
                _a.content)));
    });
    return (React.createElement(React.Fragment, null,
        bNotifyLocation && React.createElement(IconButton, { onClick: handleClickOpen },
            React.createElement(LocationOnIcon, { htmlColor: "#f0cc71" })),
        React.createElement(Dialog, { fullScreen: true, open: open, onClose: handleClose },
            React.createElement(AppBar, { sx: { position: 'relative' } },
                React.createElement(Toolbar, null,
                    React.createElement(IconButton, { edge: "start", color: "inherit", onClick: handleClose },
                        React.createElement(CloseIcon, null)),
                    currentPosition && React.createElement(React.Fragment, null,
                        "Current Position Latitude: ",
                        currentPosition.latitude,
                        ", Longitude: ",
                        currentPosition.longitude))),
            todosList)));
}
async function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition((position) => {
            resolve({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
        }, (error) => { reject(error); });
    });
}
