/* istanbul ignore file */
let db;
const request = window.indexedDB.open("BousharDB");
request.onerror = (event) => {
    console.error(`Database error: ${event.target.errorCode}`);
};
request.onsuccess = (event) => {
    db = event.target.result;
};
request.onupgradeneeded = (event) => {
    db = event.target.result;
    const objectStore = db.createObjectStore("messages", { keyPath: "created" });
};
export class Database {
    static clear() {
        const objectStore = db.transaction("messages").objectStore("messages");
        objectStore.clear();
    }
    static getAllMessages() {
        const promise = new Promise((resolve, reject) => {
            const objectStore = db.transaction("messages").objectStore("messages");
            objectStore.getAll().onsuccess = (event) => {
                resolve(event.target.result);
            };
            objectStore.getAll().onerror = (event) => {
                reject(event.target.errorCode);
            };
        });
        return promise;
    }
    static add(msg) {
        const objectStore = db.transaction(["messages"], "readwrite").objectStore("messages");
        objectStore.put(msg);
    }
}
