import { GraphClient } from "./GraphClient";
export class TodoUpdateClient extends GraphClient {
    constructor(authProvider, slot) {
        super(authProvider);
        this.slot = slot;
    }
    async update(updateTask) {
        var _a, _b;
        const listId = await this.getListId((_a = this.slot.getTaskId()) === null || _a === void 0 ? void 0 : _a.listName);
        const response = await GraphClient.graphClient.api("/me/todo/lists/" +
            listId + "/tasks/" + ((_b = this.slot.getTaskId()) === null || _b === void 0 ? void 0 : _b.taskId)).update(updateTask);
        return response;
    }
}
