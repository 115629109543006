import React from "react";
export default function TaskTitle({ title }) {
    const parts = title === null || title === void 0 ? void 0 : title.split(' ');
    const elements = parts === null || parts === void 0 ? void 0 : parts.map((v, index) => {
        if (v.startsWith('#')) {
            return React.createElement("span", { key: index, style: { fontWeight: 'bold' } },
                v,
                " ");
        }
        return React.createElement("span", { key: index },
            v,
            " ");
    });
    return React.createElement(React.Fragment, null, elements);
}
