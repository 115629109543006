import { Client } from '@microsoft/microsoft-graph-client';
import { GraphClient, IncludeTasks, Filter } from './GraphClient';
import { UpdateGraphClient } from './UpdateGraphClient';
let graphClient = undefined;
export function addChecklistItem(summary, taskId, authProvider) {
    const client = new GraphClient(authProvider);
    client.addChecklistItem(summary, taskId, authProvider);
}
export function addTask(taskSummary, authProvider) {
    const client = new GraphClient(authProvider);
    return client.addTask(taskSummary, authProvider);
}
function ensureClient(authProvider) {
    if (!graphClient) {
        graphClient = Client.initWithMiddleware({
            authProvider: authProvider
        });
    }
    return graphClient;
}
export async function getNotStartedTasks(authProvider) {
    const client = new GraphClient(authProvider);
    return client.getAll(IncludeTasks.UP_TO_TODAY, Filter.NOTSTARTED);
}
export async function getUser(authProvider) {
    const client = new GraphClient(authProvider);
    return client.getUser(authProvider);
}
export async function getTodoTaskList(authProvider) {
    ensureClient(authProvider);
    const response = await graphClient.api("/me/todo/lists").get();
    return response.value;
}
export async function getTodos(authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.getTodos('defaultList');
}
/**
    * returns all the todo tasks for the user: TodoTask, ChildItem, FlaggedEmail
    * @param authProvider
    * @returns Promise<Task>
    */
export async function getAll(authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.getAll();
}
export async function getAllAndFutureTasks(authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.getAllAndFutureTasks();
}
export async function getCheckListItems(taskId, authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.getChildListItems(taskId, authProvider);
}
export async function getCompletedTasks(authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.getCompletedTasks();
}
export async function getTask(taskId, taskType, authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.getTask(taskId, taskType, authProvider);
}
export async function markTaskComplete(slot, authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.markTaskComplete(slot);
}
export async function markChecklistItemComplete(taskId, checklistItemId, authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.markChecklistItemComplete(taskId, checklistItemId);
}
export async function snoozeTask(slot, offset, authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.snoozeTask(slot, offset);
}
export async function updateBucket(slot, bucket, authProvider) {
    const updateClient = new UpdateGraphClient(authProvider);
    const response = updateClient.updateBucket(slot, bucket);
    return response;
}
export async function updateLocation(coordinates, taskId, authProvider) {
    const graphClient = new GraphClient(authProvider);
    return graphClient.updateLocation(coordinates, taskId);
}
