import { GraphClient } from "./GraphClient";
export class MessageUpdateClient extends GraphClient {
    constructor(authProvider, slot) {
        super(authProvider);
        this.slot = slot;
    }
    async update(updateTask) {
        const response = await GraphClient.graphClient.api(`/me/messages/${this.slot.getTaskId()}`)
            .update(updateTask);
        return response;
    }
}
