import React, { useState } from "react";
import { useEffect } from "react";
import { Events } from "../utils/Belt";
import { subscribe } from "../utils/Belt";
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import { IconButton } from "@mui/material";
export default function Reload() {
    const [reloadLink, setReloadLink] = useState(false);
    useEffect(() => {
        subscribe(Events.MSGraphError, () => {
            setReloadLink(true);
        });
    }, []);
    const reload = () => {
        window.location.reload();
    };
    if (reloadLink) {
        return (React.createElement(IconButton, { onClick: reload },
            React.createElement(ErrorOutlinedIcon, { style: { color: '#e45858' } })));
    }
    else {
        return (React.createElement(React.Fragment, null));
    }
}
