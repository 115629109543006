import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { createContext, useContext, useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import React from 'react';
import { InteractionType } from "@azure/msal-browser";
import config from '../Config';
import { getUser } from "../services/GraphService/GraphService";
const appContext = createContext({
    user: undefined,
    displayError: undefined,
    authProvider: undefined,
    signIn: undefined,
    signOut: undefined
});
export function useAppContext() {
    return useContext(appContext);
}
export default function ProvideAppContext({ children }) {
    const auth = useProvideAppContext();
    return (React.createElement(appContext.Provider, { value: auth }, children));
}
function useProvideAppContext() {
    const [user, setUser] = useState(undefined);
    const [error, setError] = useState(undefined);
    const msal = useMsal();
    const displayError = (message, debug) => {
        setError({ message, debug });
    };
    msal.instance.setActiveAccount(msal.accounts[0]);
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(msal.instance, {
        account: msal.instance.getActiveAccount(),
        scopes: config.scopes,
        interactionType: InteractionType.Redirect,
    });
    useEffect(() => {
        const checkUser = async () => {
            if (!user && !error) {
                try {
                    const account = msal.instance.getActiveAccount();
                    if (account) {
                        const user = await getUser(authProvider);
                        setUser({
                            displayName: user.displayName || 'displayName',
                            userPrincipalName: user.userPrincipalName || 'userName'
                        });
                    }
                }
                catch (err) {
                    displayError(err.message);
                }
            }
        };
        checkUser();
    });
    const signIn = async () => {
        await msal.instance.loginRedirect({
            scopes: config.scopes,
            prompt: 'select_account'
        });
        const user = await getUser(authProvider);
        setUser({
            displayName: user.displayName || 'CHANGE_ME',
            userPrincipalName: user.userPrincipalName || 'CANGE_ME'
        });
    };
    const signOut = async () => {
        await msal.instance.logoutRedirect();
        setUser(undefined);
    };
    return {
        user,
        displayError,
        authProvider,
        signIn,
        signOut
    };
}
