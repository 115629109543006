import moment from "moment-timezone";
class DateTimeUtils {
    constructor() {
        this.moment = moment();
    }
    add(amount, units) {
        return dateTimeUtils(moment(this.moment).add(amount, units));
    }
    diff(m, units) {
        return this.moment.diff(m.moment, 'seconds');
    }
    format(pattern) {
        return this.moment.tz(moment.tz.guess()).format(pattern);
    }
    getDayOfTheMonth() {
        return this.moment.date();
    }
    isAfterNow() {
        return this.moment.isAfter(moment());
    }
    isBeforeNow() {
        return this.moment.isBefore(moment());
    }
    toDate() {
        return this.moment.toDate();
    }
}
export default function dateTimeUtils(m) {
    const utils = new DateTimeUtils();
    if (m === undefined) {
        return utils;
    }
    if (m instanceof moment) {
        utils.moment = m;
    }
    else if (typeof m === 'string') {
        utils.moment = moment(Date.parse(m));
    }
    else if (typeof m === 'number') {
        utils.moment = moment(m);
    }
    else { // must be DateTimeTimeZone. TODO add guard for this
        const dtz = m;
        utils.moment = moment.tz(dtz.dateTime, dtz.timeZone);
    }
    return utils;
}
export function calculateMinutesSince(dateTime) {
    return moment().diff(moment(dateTime), "minute");
}
export function calculateSecondsSince(dateTime) {
    return moment().diff(moment(dateTime), "second");
}
export function getFormattedSeconds(seconds) {
    const duration = moment.duration(seconds, 'seconds');
    return duration.hours().toString().padStart(2, '0') + ':' + duration.minutes().toString().padStart(2, '0') +
        ':' + duration.seconds().toString().padStart(2, '0');
}
