import React, { useEffect } from "react";
import { fibonacci } from "../utils";
import * as d3 from 'd3';
import './Progress.scss';
export const Progress = ({ level, total, current }) => {
    const d3Ref = React.useRef(null);
    useEffect(() => {
        const barWidth = (current - fibonacci(level - 1)) / (total - fibonacci(level - 1));
        const width = 120;
        const height = 24;
        const $div = d3.select(d3Ref.current);
        $div.selectAll('svg').remove();
        const $svg = $div.append("svg").attr("width", width).attr("height", height);
        const $rect = $svg.append('rect')
            .attr('x', 1)
            .attr('y', 1)
            .attr('width', width)
            .attr('height', height)
            .attr('stroke', 'black')
            .attr('fill', '#000000');
        $svg
            .append('rect').attr('x', 0).attr('y', 0).attr('width', barWidth * width).attr('height', height)
            .attr('fill', '#1976d2');
        const label = `${level} ${current}/${total}`;
        $svg.append('text').text(label).attr('x', 1).attr('y', 3).attr('dx', '10').attr('dy', '15');
        const textWidth = getTextWidth(label, window.getComputedStyle(d3Ref.current));
        if (textWidth + 10 > width) {
            $svg.attr('width', textWidth + 10);
            $rect.attr('width', textWidth + 10);
        }
    }, [level, total, current]);
    return (React.createElement("div", { ref: d3Ref, style: { paddingTop: '5px' } }));
};
function getTextWidth(text, font) {
    // Create a hidden span element
    let span = document.createElement("span");
    // Set the text content to the span
    span.textContent = text;
    // Apply the font style to the span
    span.style.font = font;
    // Set the span to be hidden but measurable
    span.style.visibility = "hidden";
    span.style.whiteSpace = "nowrap";
    document.body.appendChild(span);
    // Measure the width of the span
    let width = span.offsetWidth;
    // Remove the span from the document
    document.body.removeChild(span);
    return width;
}
