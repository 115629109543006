class Belt {
    constructor() {
        let $div = document.createElement('div');
        $div.setAttribute('id', '_boushar_belt');
        document.body.appendChild($div);
    }
    static getInstance() {
        if (!Belt.instance) {
            Belt.instance = new Belt();
        }
        return Belt.instance;
    }
    publish(type, data) {
        var _a;
        const event = new CustomEvent(type, { detail: data });
        (_a = document.getElementById('_boushar_belt')) === null || _a === void 0 ? void 0 : _a.dispatchEvent(event);
    }
    subscribe(type, callback) {
        var _a;
        (_a = document.getElementById('_boushar_belt')) === null || _a === void 0 ? void 0 : _a.addEventListener(type, (e) => {
            callback(e.detail);
        });
    }
    unsbscribe(type, callback) {
        var _a;
        (_a = document.getElementById('_boushar_belt')) === null || _a === void 0 ? void 0 : _a.removeEventListener(type, callback, false);
    }
}
export var Events;
(function (Events) {
    Events["DATA_READY"] = "DATA_READY";
})(Events || (Events = {}));
export const publish = (type, data) => {
    Belt.getInstance().publish(type, data);
};
export const subscribe = (type, callback) => {
    Belt.getInstance().subscribe(type, callback);
};
export const unsubscribe = (type, callback) => {
    Belt.getInstance().unsbscribe(type, callback);
};
