import { CategoryFiveProcessor } from "./CategoryFiveProcessor";
import { CategoryOneProcessor } from "./CategoryOneProcessor";
import { CategoryThreeProcessor } from "./CategoryThreeProcessor";
function findTodoNotOnBoard(todos, slots) {
    if (todos == null)
        return null;
    for (let i = 0; i < todos.length; i++) {
        if (slots.isTodoOnBoard(todos[i]))
            continue;
        return todos[i];
    }
    return null;
}
export class SlotsProcessor {
    static async process(dispatch, authProvider, slots, todos) {
        const processor = new CategoryOneProcessor(dispatch, authProvider, slots, todos);
        await processor.process(0, 0);
        const processor3 = new CategoryThreeProcessor(dispatch, authProvider, slots, todos);
        await processor3.process(1, 3);
        const processor5 = new CategoryFiveProcessor(dispatch, authProvider, slots, todos);
        await processor5.process(4, 8);
    }
}
// fill the empty slots
export function fillEmptySlots(todos, aSlots, slots, authProvider) {
    for (let i = 0; i < aSlots.length; ++i) {
        if (aSlots[i].isEmpty()) {
            slots.resetSlot(i);
            if (todos == null)
                continue;
            const nonCompletedTodos = todos.filter((t) => (t.getStatus() != 'completed'));
            const todo = findTodoNotOnBoard(nonCompletedTodos, slots);
            if (todo != null) {
                slots.fillSlot(i, todo);
            }
        }
    }
}
