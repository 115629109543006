import { Events, subscribe } from "./Belt";
import { Database } from "./Database";
export var Level;
(function (Level) {
    Level[Level["INFO"] = 0] = "INFO";
    Level[Level["ERROR"] = 1] = "ERROR";
    Level[Level["DEBUG"] = 2] = "DEBUG";
})(Level || (Level = {}));
export class Message {
    constructor(level, msg) {
        this.level = level;
        this.msg = msg;
        this.created = (new Date()).getTime();
    }
}
const log = [];
let bDebug = false;
subscribe(Events.DEBUG, () => {
    bDebug = true;
});
class Logger {
    static clear() {
        Database.clear();
    }
    static error(err) {
        console.error(err);
        //log.push(new Message(Level.ERROR, err.toString()));
        Database.add(new Message(Level.ERROR, err.toString()));
    }
    static info(o) {
        console.info(o);
        //log.push(new Message(Level.INFO, o));
        Database.add(new Message(Level.INFO, o));
    }
    static debug(msg) {
        if (bDebug) {
            console.debug(msg);
            //log.push(new Message(Level.DEBUG, msg.toString()))
            Database.add(new Message(Level.DEBUG, msg));
        }
    }
    static async getAllLogs() {
        const messages = await Database.getAllMessages();
        return messages;
    }
}
export default Logger;
