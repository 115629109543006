import React from "react";
import { useDispatch } from "react-redux";
import { tickClock } from "../features/clock/clockSlice";
export default function Scheduler() {
    const dispatch = useDispatch();
    React.useEffect(() => {
        const id = setInterval(() => {
            dispatch(tickClock({}));
        }, 1000);
        return () => { clearInterval(id); };
    }, []);
    return React.createElement(React.Fragment, null);
}
