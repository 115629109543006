import { Bucket } from "../../onethreefive/model/Bucket";
export class Slot {
    static createSlot(index, listName, tid = null, added = null, title = null, status = undefined, headerTitle = undefined, checkListItemId = "", type = "TodoTask", webLink = undefined) {
        let name;
        switch (index) {
            case 0:
                name = "slot1";
                break;
            case 1:
            case 2:
            case 3:
                name = ("slot3" + index);
                break;
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
                name = ("slot5" + (index - 3));
                break;
            default:
                throw Error("Logic Error, slot index has to be [0-8]");
        }
        return new Slot({ name, tid, added, title, status, headerTitle, checkListItemId, type, webLink, listName });
    }
    static copySlot(slot) {
        return new Slot(slot);
    }
    constructor(s) {
        this.name = s.name;
        this.tid = s.tid;
        this.added = s.added;
        this.title = s.title;
        this.status = s.status;
        this.headerTitle = s.headerTitle;
        this.checkListItemId = s.checkListItemId;
        this.type = s.type;
        this.webLink = s.webLink;
        this.listName = s.listName;
    }
    getAdded() {
        return this.added;
    }
    getBucket() {
        if (this.name === 'slot1') {
            return Bucket.ONE;
        }
        else if (this.name.startsWith('slot3')) {
            return Bucket.THREE;
        }
        else if (this.name.startsWith('slot5')) {
            return Bucket.FIVE;
        }
        throw (new Error("Invalid Slot name"));
    }
    getCheckListItemId() {
        return this.checkListItemId;
    }
    getIndex() {
        const b = this.getBucket();
        switch (b) {
            case Bucket.ONE:
                return 0;
            case Bucket.THREE:
                return parseInt(this.name.charAt(this.name.length - 1));
            case Bucket.FIVE:
                return parseInt(this.name.charAt(this.name.length - 1)) + 3;
        }
    }
    getName() {
        return this.name;
    }
    getTaskId() {
        return this.tid;
    }
    object() {
        return JSON.parse(JSON.stringify(this));
    }
    isCheckListItem() {
        return this.checkListItemId != null && this.checkListItemId != "";
    }
    isComplete() {
        return this.status === 'completed';
    }
    isEmpty() {
        return this.tid == null;
    }
    reset() {
        this.tid = undefined;
        this.added = null;
        this.status = undefined;
        this.title = null;
    }
}
function applyMixins(derivedCtor, baseCtors) {
    baseCtors.forEach(baseCtor => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
            Object.defineProperty(derivedCtor.prototype, name, Object.getOwnPropertyDescriptor(baseCtor.prototype, name) ||
                Object.create(null));
        });
    });
}
