import { Client, PageIterator } from "@microsoft/microsoft-graph-client";
import { Events, publish } from "../../utils/Belt";
import Logger from "../../utils/Logger";
import moment from 'moment-timezone';
import { Task } from "../../onethreefive/model/Task";
import { GraphCache } from "./GraphCache";
const LISTS = '/me/todo/lists';
function ensureClient(authProvider) {
    if (!GraphClient.graphClient) {
        GraphClient.graphClient = Client.initWithMiddleware({
            authProvider: authProvider
        });
    }
    return GraphClient.graphClient;
}
var CallType;
(function (CallType) {
    CallType[CallType["GET_TASK"] = 0] = "GET_TASK";
    CallType[CallType["GET_FLAGGED_EMAILS"] = 1] = "GET_FLAGGED_EMAILS";
    CallType[CallType["GET_EMAIL_FOLDER"] = 2] = "GET_EMAIL_FOLDER";
    CallType[CallType["GET_CHILDREN"] = 3] = "GET_CHILDREN";
    CallType[CallType["UPDATE_TASK"] = 4] = "UPDATE_TASK";
})(CallType || (CallType = {}));
export var IncludeTasks;
(function (IncludeTasks) {
    IncludeTasks[IncludeTasks["INCLUDE_FUTURE"] = 0] = "INCLUDE_FUTURE";
    IncludeTasks[IncludeTasks["UP_TO_TODAY"] = 1] = "UP_TO_TODAY";
})(IncludeTasks || (IncludeTasks = {}));
export var Filter;
(function (Filter) {
    Filter[Filter["COMPLETED"] = 0] = "COMPLETED";
    Filter[Filter["NOTSTARTED"] = 1] = "NOTSTARTED";
    Filter[Filter["NOFILTER"] = 2] = "NOFILTER";
})(Filter || (Filter = {}));
export class GraphClient {
    constructor(authProvider) {
        this.appThrottled = false;
        this.cache = new GraphCache();
        this.authProvider = authProvider;
    }
    async addChecklistItem(summary, taskId, authProvider) {
        this.authProvider = authProvider;
        const url = await this.getListBaseUrl(taskId.listName);
        return GraphClient.graphClient.api(`${url}/${taskId.taskId}/checklistItems`).post({
            displayName: summary
        });
    }
    async getTask(taskId, taskType, authProvider) {
        this.authProvider = authProvider;
        if (this.cache.isTaskInCache(taskId === null || taskId === void 0 ? void 0 : taskId.taskId)) {
            return this.cache.get(taskId === null || taskId === void 0 ? void 0 : taskId.taskId);
        }
        else {
            const listId = await this.getListId(taskId === null || taskId === void 0 ? void 0 : taskId.listName);
            const baseUrl = `/me/todo/lists/${listId}/tasks`;
            const response = await GraphClient.graphClient.api(`${baseUrl}/${taskId.taskId}`).get();
            const task = Task.makeTask(response, null, taskId.listName);
            this.cache.put(taskId === null || taskId === void 0 ? void 0 : taskId.taskId, task);
            return task;
        }
    }
    async getChildListItems(taskId, authProvider) {
        this.authProvider = authProvider;
        return this.apiCall(CallType.GET_CHILDREN, { taskId });
    }
    async addTask(title, authProvider) {
        this.authProvider = authProvider;
        const baseUrl = await this.getListBaseUrl('defaultList');
        return await GraphClient.graphClient.api(baseUrl).post({ title });
    }
    async apiCall(type, payload) {
        var _a, _b, _c, _d, _e, _f;
        try {
            ensureClient(this.authProvider);
            const listName = (_a = payload.taskId) === null || _a === void 0 ? void 0 : _a.listName;
            const baseUrl = await this.getListBaseUrl(listName);
            switch (type) {
                case CallType.GET_TASK:
                    if (this.cache.isTaskInCache((_b = payload.taskId) === null || _b === void 0 ? void 0 : _b.taskId)) {
                        return this.cache.get((_c = payload.taskId) === null || _c === void 0 ? void 0 : _c.taskId);
                    }
                    else {
                        let task = undefined;
                        const response = await GraphClient.graphClient.api(`${baseUrl}/${payload.taskId}`).get();
                        task = Task.makeTask(response, null, listName);
                        this.cache.put((_d = payload.taskId) === null || _d === void 0 ? void 0 : _d.taskId, task);
                        return task;
                    }
                case CallType.GET_CHILDREN:
                    const res = await GraphClient.graphClient.api("/me/todo/lists/" + listName + "/tasks/"
                        + payload.taskId
                        + "/checklistItems").get();
                    return res.value;
                case CallType.GET_FLAGGED_EMAILS:
                    // https://graph.microsoft.com/v1.0/me/messages?$filter=flag/flagStatus eq 'flagged'
                    {
                        const response = await GraphClient.graphClient.api("me/messages?$filter=flag/flagStatus eq 'flagged'")
                            //const response = await graphClient!.api("me/messages")
                            .get();
                        return response.value;
                    }
                case CallType.UPDATE_TASK:
                    const response = await GraphClient.graphClient.api(`${baseUrl}/${payload.taskId}`).update(payload.updateTask);
                    return response;
                case CallType.GET_EMAIL_FOLDER:
                    {
                        const response = await GraphClient.graphClient.api(`me/mailFolders/${payload.folderId}`).get();
                        return response;
                    }
            }
        }
        catch (error) {
            Logger.error(error);
            if (error.code == 'activityLimitReached') {
                this.turnOnThrottle();
            }
            if (((_e = error.body) === null || _e === void 0 ? void 0 : _e.indexOf("no_account_error")) != -1 ||
                ((_f = error.body) === null || _f === void 0 ? void 0 : _f.indexOf("NetworkError when attempting to fetch resource")) != -1) {
                return;
            }
            publish(Events.MSGraphError, error);
            throw error;
        }
        finally {
            this.cache.cleanup();
        }
    }
    async getFlaggedEmailed() {
        return await this.apiCall(CallType.GET_FLAGGED_EMAILS, {});
    }
    async getUser(authProvider) {
        ensureClient(authProvider);
        const user = await GraphClient.graphClient.api('/me')
            .select('displayName')
            .select('userPrincipalName')
            .get();
        return user;
    }
    async getTodos(listName, include = IncludeTasks.UP_TO_TODAY, filter = Filter.NOFILTER) {
        assertNotNull(this.authProvider);
        const listId = await this.getListId(listName);
        let tasksUrl = `/me/todo/lists/${listId}/tasks`;
        if (filter == Filter.NOTSTARTED) {
            tasksUrl += "?$filter=status eq 'notStarted'";
        }
        else if (filter == Filter.COMPLETED) {
            tasksUrl += "?$filter=status eq 'completed'";
        }
        const response = await this.get(`${tasksUrl}`);
        if (response["@odata.nextLink"]) {
            // Presence of the nextLink property indicates more results are available
            // Use a page iterator to get all results
            const tasks = [];
            let pageIterator = new PageIterator(GraphClient.graphClient, response, (event) => {
                if (event.dueDateTime == undefined ||
                    (event.dueDateTime != undefined && moment.tz(event.dueDateTime.dateTime, event.dueDateTime.timeZone).isBefore(moment()))) {
                    if (event.reminderDateTime == undefined || (event.reminderDateTime != undefined && moment.tz(event.reminderDateTime.dateTime, 'UTC').isBefore(moment()))) {
                        tasks.push(Task.makeTask(event, null, listName));
                    }
                }
                return true;
            });
            if (include == IncludeTasks.INCLUDE_FUTURE) {
                pageIterator = new PageIterator(GraphClient.graphClient, response, (event) => {
                    tasks.push(Task.makeTask(event, null, listName));
                    return true;
                });
            }
            await pageIterator.iterate();
            return tasks.sort((a, b) => (a.getStartDateTime().diff(b.getStartDateTime(), 'seconds')));
        }
        else {
            const tasks = [];
            response.value.map((todo) => {
                if (include == IncludeTasks.INCLUDE_FUTURE || todo.dueDateTime == undefined ||
                    (todo.dueDateTime != undefined &&
                        moment.tz(todo.dueDateTime.dateTime, todo.dueDateTime.timeZone != undefined ? 'UTC' : todo.dueDateTime.timeZone).isBefore(moment()))) {
                    if (include == IncludeTasks.INCLUDE_FUTURE || todo.reminderDateTime == undefined ||
                        (todo.reminderDateTime != undefined &&
                            moment.tz(todo.reminderDateTime.dateTime, 'UTC').isBefore(moment()))) {
                        tasks.push(Task.makeTask(todo, null, listName));
                    }
                }
            });
            return tasks.sort((a, b) => (a.getStartDateTime().diff(b.getStartDateTime(), 'seconds')));
        }
    }
    async getAllAndFutureTasks() {
        return this.getAll(IncludeTasks.INCLUDE_FUTURE, Filter.NOTSTARTED);
    }
    /**
     * returns all the todo tasks for the user: TodoTask, ChildItem, FlaggedEmail
     * @returns Promise<Task>
     */
    async getAll(include = IncludeTasks.UP_TO_TODAY, filter = Filter.NOFILTER) {
        const tasks = [];
        for (const listName of ["defaultList", "flaggedEmails"]) {
            const todoTasks = await this.getTodos(listName, include, filter);
            for (let i = 0; i < todoTasks.length; ++i) {
                let hasNoneCheckedItem = false;
                if (todoTasks[i].hasChecklistItems()) {
                    const items = todoTasks[i].getItems() !== undefined ? todoTasks[i].getItems() : [];
                    for (let j = 0; j < items.length; ++j) {
                        if (!items[j].isChecked) {
                            hasNoneCheckedItem = true;
                        }
                        tasks.push(Task.makeTask(todoTasks[i].getTodoTask(), items[j], listName));
                    }
                }
                if (!hasNoneCheckedItem) {
                    tasks.push(Task.makeTask(todoTasks[i].getTodoTask(), null, listName));
                }
            }
        }
        return tasks;
    }
    async getCompletedTasks() {
        return this.getAll(IncludeTasks.INCLUDE_FUTURE, Filter.COMPLETED);
    }
    async getNotStartedTasks() {
        return this.getAll(IncludeTasks.INCLUDE_FUTURE);
    }
    async markChecklistItemComplete(taskId, checklistItemId) {
        const updateChecklistItem = {
            isChecked: "true"
        };
        const baseUrl = await this.getListBaseUrl(taskId.listName);
        const r = await GraphClient.graphClient.api(`${baseUrl}/${taskId.taskId}/checklistItems/${checklistItemId}`)
            .update(updateChecklistItem);
        return r;
    }
    async markTaskComplete(slot) {
        var _a, _b;
        const updateTodoTask = {
            status: "completed"
        };
        const baseUrl = await this.getListBaseUrl((_a = slot.getTaskId()) === null || _a === void 0 ? void 0 : _a.listName);
        const response = await GraphClient.graphClient.api(baseUrl + ((_b = slot.getTaskId()) === null || _b === void 0 ? void 0 : _b.taskId)).update(updateTodoTask);
        return response;
    }
    async snoozeTask(slot, offset) {
        var _a, _b;
        const tid = (_a = slot.getTaskId()) === null || _a === void 0 ? void 0 : _a.taskId;
        const listName = (_b = slot.getTaskId()) === null || _b === void 0 ? void 0 : _b.listName;
        const newDateTime = moment().add(offset, 'd').format('YYYY-MM-DDTHH:mm:ss');
        const tz = moment.tz.guess();
        let updateTask = {
            reminderDateTime: null,
            dueDateTime: {
                dateTime: newDateTime,
                timeZone: tz
            }
        };
        const defaultListId = await this.getListId(listName);
        let url = `/me/todo/lists/${defaultListId}/tasks/${tid}`;
        if (slot.type === 'Message') {
            url = `/me/messages/${slot.getTaskId()}`;
            updateTask = {
                "flag": {
                    "flagStatus": "flagged",
                    "startDateTime": {
                        dateTime: newDateTime,
                        timeZone: 'UTC'
                    },
                    "dueDateTime": {
                        dateTime: newDateTime,
                        timeZone: 'UTC'
                    }
                }
            };
        }
        const response = await GraphClient.graphClient.api(url).update(updateTask);
        return response;
    }
    async markMessageUnflagged(taskId) {
        var _a;
        if (taskId == undefined) {
            throw new Error("Logic Error: taskId is undefined");
        }
        ensureClient(this.authProvider);
        const body = {
            flag: {
                flagStatus: 'notFlagged'
            }
        };
        const response = await ((_a = GraphClient.graphClient) === null || _a === void 0 ? void 0 : _a.api(`me/messages/${taskId}`).update(body));
        return response;
    }
    async updateLocation(coordinates, taskId) {
        const updateTask = {
            body: {
                content: JSON.stringify(coordinates)
            }
        };
        const defaultList = await this.getListId('defaultList');
        const response = await GraphClient.graphClient.api("/me/todo/lists/" + defaultList + "/tasks/" + taskId).update(updateTask);
        return response;
    }
    async updateTask(taskId, updateTask) {
        this.apiCall(CallType.UPDATE_TASK, { taskId, updateTask });
    }
    async getListId(listName) {
        const response = await this.get(LISTS);
        for (let i = 0; i < response.value.length; ++i) {
            const list = response.value[i];
            if (list.wellknownListName === listName) {
                return list.id;
            }
        }
        return null;
    }
    turnOnThrottle() {
        if (this.appThrottled) {
            return;
        }
        this.appThrottled = true;
        setTimeout(() => { this.appThrottled = false; }, 1000);
    }
    async get(url) {
        try {
            ensureClient(this.authProvider);
            const response = await GraphClient.graphClient.api(url).get();
            return response;
        }
        catch (error) {
            console.log(error);
        }
    }
    async getListBaseUrl(listName) {
        const listId = await this.getListId(listName);
        return `/me/todo/lists/${listId}/tasks/`;
    }
}
GraphClient.graphClient = undefined;
function assertNotNull(obj) {
    if (obj == null || obj == undefined) {
        throw (new Error(`{obj} is null`));
    }
}
