import React from "react";
import { useAppContext } from "../hooks/AppContext";
import Alert from '@mui/material/Alert';
export default function ErrorMessage() {
    const app = useAppContext();
    if (app.error) {
        return (React.createElement(Alert, { severity: "error", onClose: () => app.clearError() },
            React.createElement("p", { className: "mb-3" }, app.error.message),
            app.error.debug ?
                React.createElement("pre", { className: "alert-pre border bg-light p-2" },
                    React.createElement("code", null, app.error.debug))
                : null));
    }
    return null;
}
