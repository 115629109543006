import { TodoUpdateClient } from "./TodoUpdateClient";
import { MessageUpdateClient } from "./MessageUpdateClient";
export class GraphClientFactory {
    static makeUpdateClient(authProvider, slot) {
        if (slot.type === 'Message') {
            return new MessageUpdateClient(authProvider, slot);
        }
        else {
            return new TodoUpdateClient(authProvider, slot);
        }
    }
}
