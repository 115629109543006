import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { AppComponent } from "../features/onethreefive/components/App";
import { useAppContext } from "../hooks/AppContext";
import { ProgressOverlay } from "./ProgressOverlay";
export default function OneThreeFive() {
    const app = useAppContext();
    const [bOverlay, setOverlay] = useState(false);
    const { instance } = useMsal();
    useEffect(() => {
        if (app.user !== undefined) {
            setOverlay(false);
        }
    }, [app.user]);
    return (React.createElement(React.Fragment, null,
        React.createElement(ProgressOverlay, { open: bOverlay }),
        React.createElement(UnauthenticatedTemplate, null,
            React.createElement("div", { style: { paddingTop: "calc(10vh)", justifyContent: "center", display: "flex" } },
                React.createElement(Button, { variant: "contained", onClick: (e) => {
                        app.signIn(e);
                        setOverlay(true);
                    } }, "Sign In"))),
        React.createElement(AuthenticatedTemplate, null,
            React.createElement(AppComponent, null))));
}
