import { cloneDeep } from 'lodash';
import { FilterEnum } from "./Weight";
import { Units } from "./settingsSlice";
const weightColor = "rgb(125,125,125,0.5)";
let chartData = {
    labels: [],
    datasets: [
        {
            label: "weight",
            backgroundColor: weightColor,
            borderColor: weightColor,
            pointRadius: 1,
            pointHitRadius: 20,
            fill: false,
            data: []
        },
    ]
};
function filterOutEntries(n, dates, weightins) {
    const reverseDates = dates.reverse();
    for (let i = n; i < reverseDates.length; ++i) {
        delete weightins[reverseDates[i]];
    }
}
class DataProcessor {
    calculate(a) {
        throw new Error("Method not implemented.");
    }
    augment(_weightins, filter, unit) {
        const weightins = cloneDeep(_weightins);
        const dates = Object.keys(weightins).sort();
        if (unit == Units.KG) {
            dates.forEach(k => {
                weightins[k].weight = weightins[k].weight * 0.45359237;
            });
        }
        this.generateNDaysAverage(weightins, dates, 7, 'sevenDaysAverage');
        this.generateNDaysAverage(weightins, dates, 30, 'thirtyDaysAverage');
        if (filter === FilterEnum.THIRTY) {
            filterOutEntries(30, dates, weightins);
        }
        else if (filter === FilterEnum.SEVEN) {
            filterOutEntries(7, dates, weightins);
        }
        else if (filter === FilterEnum.YEAR) {
            filterOutEntries(365, dates, weightins);
        }
        return weightins;
    }
    generateNDaysAverage(weightins, dates, nDays, columnName) {
        for (let i = 0; i < dates.length; ++i) {
            let d = dates[i];
            if (i < nDays) {
                let sum_i = 0;
                for (let k = 0; k <= i; ++k) {
                    sum_i += weightins[dates[k]].weight;
                }
                weightins[dates[i]][columnName] = sum_i / (i + 1);
                continue;
            }
            let sum = 0;
            for (let j = 0; j < (nDays); ++j) {
                sum += weightins[dates[i - j]].weight;
            }
            weightins[dates[i]][columnName] = sum / nDays;
        }
    }
    identity(a) {
        return a;
    }
    static createDataProcessor() {
        return new DataProcessor();
    }
    static process(data) {
        const now = new Date();
        const month = now.getMonth();
        const year = now.getFullYear();
        chartData.labels = [];
        chartData.datasets[0].data = [];
        Object.keys(data).sort().forEach((key) => {
            if (new Date(Date.parse(data[key].date)).getFullYear() == year &&
                new Date(Date.parse(data[key].date)).getMonth() == month) {
                chartData.labels.push(data[key].date);
                chartData.datasets[0].data.push(data[key].weight);
            }
        });
        return chartData;
    }
}
export default DataProcessor;
