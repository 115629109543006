import { Checkbox, CircularProgress, Dialog, DialogContent, IconButton, Link, Snackbar, TextField, useMediaQuery, useTheme } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/AppContext";
import { addChecklistItem, getTask, markChecklistItemComplete, snoozeTask } from "../../services/GraphService/GraphService";
import dateTimeUtils, { getFormattedSeconds } from "../../utils/DateTimeUtils";
import { CategoryProcessor } from "../processors/CategoryProcessor";
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import CountdownTimer from "../../components/CountdownTimer";
import { StorageUtils } from "../../utils/StorageUtils";
import { ProgressOverlay } from "../../components/ProgressOverlay";
const labelStyle = {
    color: 'rgba(0, 0, 0, 0.6)',
    transform: 'translate(0, -1.5px) scale(0.75)',
    transformOrigin: 'top left'
};
export const TaskDetailView = ({ open, closeDetailDialog, slot }) => {
    var _a;
    const app = useAppContext();
    const [bCountdown, setCountdown] = useState(false);
    const [task, setTask] = useState();
    const [items, setItems] = useState([]);
    const [bBackdrop, setBackdrop] = useState(false);
    const [workedTime, setWorkedTime] = useState(0);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const taskId = slot.getTaskId();
    const addChecklistItemRef = React.useRef();
    const [bProgressOverlayOpen, setProgressOverlayOpen] = useState(false);
    const [bOpenSnack, setOpenSnack] = useState(false);
    const [snackTitle, setSnackTitle] = useState('');
    const retrieveTask = async () => {
        var _a, _b, _c, _d;
        const task = await getTask(taskId, slot.type, app.authProvider);
        // const items = await getCheckListItems(taskId, app.authProvider)
        setTask(task);
        setItems(((_a = task.todoTask) === null || _a === void 0 ? void 0 : _a.checklistItems) != undefined ?
            (_b = task.todoTask) === null || _b === void 0 ? void 0 : _b.checklistItems : []);
        // setItems(items)
        setWorkedTime(StorageUtils.loadWorkedTimeFromStorage((_c = app.user) === null || _c === void 0 ? void 0 : _c.userPrincipalName, (_d = slot.tid) === null || _d === void 0 ? void 0 : _d.taskId));
    };
    useEffect(() => {
        setProgressOverlayOpen(true);
        retrieveTask().finally(() => { setProgressOverlayOpen(false); });
    }, []);
    const toggleCheckbox = (event, i) => {
        const checked = event.target.checked;
        if (checked) {
            markChecklistItemComplete(task === null || task === void 0 ? void 0 : task.getId(), i.id, app.authProvider);
            retrieveTask();
        }
    };
    const $items = items.map((i, idx) => (React.createElement("div", { key: idx, style: { display: 'flex' } },
        React.createElement(Checkbox, { onChange: (e) => { toggleCheckbox(e, i); }, size: "small", disabled: i.isChecked, checked: i.isChecked }),
        React.createElement("div", { style: { alignSelf: 'center', textDecoration: i.isChecked ? "line-through" : "none" } }, i.displayName))));
    /*const markComplete = () => {
       const doMarkComplete = async () => {
          await markTaskComplete(slot, app.authProvider!);
          setBackdrop(false);
       }
       setBackdrop(true)
       doMarkComplete();
    }*/
    /*const locationUpdate = () => {
       navigator.geolocation.getCurrentPosition((position) => {
          const geo: GeoCoordinates = {
             latitude: position.coords.latitude,
             longitude: position.coords.longitude
          }
          updateLocation(geo, taskId!, app.authProvider!)
       })
    }*/
    const handleSnoozeTask = async () => {
        const offset = await CategoryProcessor.snoozeTask(app.authProvider, slot);
        snoozeTask(slot, offset, app.authProvider);
    };
    const onAddChecklistItem = async () => {
        // call graphservice add checklistitem
        await addChecklistItem(addChecklistItemRef.current.value, taskId, app.authProvider);
        setSnackTitle(`Added: ${addChecklistItemRef.current.value}`);
        setOpenSnack(true);
        addChecklistItemRef.current.value = "";
        retrieveTask();
    };
    const handleChecklistItemInputKeyUp = (event) => {
        if (event.key === 'Enter') {
            onAddChecklistItem();
        }
    };
    const handleSnackClose = (_, reason) => {
        if (reason == 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Snackbar, { open: bOpenSnack, autoHideDuration: 6000, anchorOrigin: { vertical: 'top', horizontal: 'center' }, message: snackTitle, onClose: handleSnackClose }),
        React.createElement(ProgressOverlay, { open: bProgressOverlayOpen }),
        React.createElement(Dialog, { open: open, fullScreen: fullScreen },
            React.createElement(DialogContent, { style: { backgroundColor: '#faf9f8' } },
                React.createElement(Backdrop, { open: bBackdrop, sx: { zIndex: (theme) => theme.zIndex.drawer + 1 } },
                    React.createElement(CircularProgress, { color: "inherit" })),
                React.createElement("div", { style: { display: 'grid', gridTemplateColumns: '1', gap: '10px' } },
                    React.createElement("div", { style: { display: 'grid', gridTemplateColumns: 'auto 1fr' } },
                        React.createElement("div", null, task === null || task === void 0 ? void 0 : task.getTitle()),
                        React.createElement("div", { style: { textAlign: 'end' } },
                            React.createElement(Link, { target: "_blank", href: `https://to-do.live.com/tasks/id/${taskId === null || taskId === void 0 ? void 0 : taskId.taskId}/details` },
                                React.createElement(LinkIcon, null)))),
                    task === null && React.createElement("div", null, "Loading"),
                    task !== null &&
                        React.createElement(React.Fragment, null,
                            React.createElement("div", { style: { display: 'inline-flex', flexDirection: 'column' } },
                                React.createElement("label", { style: labelStyle }, "Status"),
                                React.createElement("div", null, slot.status != 'completed' ? 'open' : 'completed')),
                            React.createElement("div", { style: { display: 'inline-flex', flexDirection: 'column' } },
                                React.createElement("label", { style: labelStyle }, "Time Spent"),
                                React.createElement("div", null, getFormattedSeconds(workedTime))),
                            React.createElement("div", { style: { display: 'inline-flex', flexDirection: 'column' } },
                                React.createElement("label", { style: labelStyle }, "Reminder Date"),
                                React.createElement("div", null, (task === null || task === void 0 ? void 0 : task.getReminderDateTime()) ? dateTimeUtils(task === null || task === void 0 ? void 0 : task.getReminderDateTime()).format("M/D/Y H:mm") : "")),
                            React.createElement("div", { style: { display: 'inline-flex', flexDirection: 'column' } },
                                React.createElement("label", { style: labelStyle }, "Due Date"),
                                React.createElement("div", null, (task === null || task === void 0 ? void 0 : task.getDueDateTime()) ? dateTimeUtils(task.getDueDateTime()).format("M/D/Y") : "")),
                            React.createElement("div", { style: { width: '100%', display: 'flex' } },
                                React.createElement(TextField, { variant: "standard", size: "small", placeholder: "Add Checklist item", inputRef: addChecklistItemRef, style: { flexGrow: 1 }, inputProps: { style: { flexGrow: 1 } }, onKeyUp: handleChecklistItemInputKeyUp }),
                                React.createElement(IconButton, { onClick: onAddChecklistItem },
                                    React.createElement(AddIcon, null))),
                            React.createElement("div", { style: { width: '100%', display: 'flex', flexDirection: 'column' } }, $items),
                            React.createElement(Paper, { elevation: 0, style: { marginBottom: '10px' } },
                                React.createElement(TextField, { multiline: true, fullWidth: true, defaultValue: (_a = task === null || task === void 0 ? void 0 : task.getBody()) === null || _a === void 0 ? void 0 : _a.content })))),
                React.createElement(Button, { variant: "contained", onClick: () => setCountdown(true) }, "Start"),
                React.createElement(Button, { style: { marginLeft: '10px' }, variant: "contained", onClick: closeDetailDialog }, "Close"),
                bCountdown && React.createElement(CountdownTimer, { handleClose: () => setCountdown(false), slot: slot })))));
};
