import React from 'react';
export default function CountDownIndicator({ count }) {
    if (count <= 0) {
        return React.createElement(React.Fragment, null);
    }
    let list = [];
    for (let i = 0; i < count; ++i) {
        list.push(React.createElement("div", { style: { marginLeft: '2px', backgroundColor: 'royalblue', color: 'royalblue' } }, "X"));
    }
    return React.createElement("div", { style: { display: 'flex', marginBottom: '10px' } }, list);
}
