import { createSlice } from "@reduxjs/toolkit";
const initialState = (new Date()).toString();
const clockSlice = createSlice({
    name: "clock",
    initialState,
    reducers: {
        tickClock: (state, action) => {
            state = (new Date()).toString();
            return state;
        }
    }
});
export const { tickClock } = clockSlice.actions;
export default clockSlice;
