import Button from '@mui/material/Button';
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../hooks/AppContext';
import { StorageUtils } from '../utils/StorageUtils';
import dateTimeUtils from '../utils/DateTimeUtils';
const PERIOD = 30 * 60;
let workedTime = 0;
function CountdownTimer({ handleClose, slot }) {
    const [timeRemaining, setTimeRemaining] = useState(PERIOD); // 30 minutes in seconds
    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(true);
    const [endTime, setEndTime] = useState(dateTimeUtils(undefined).add(PERIOD, 'seconds'));
    const app = useAppContext();
    useEffect(() => {
        var _a, _b;
        // load workedTime from storage
        workedTime = StorageUtils.loadWorkedTimeFromStorage((_a = app.user) === null || _a === void 0 ? void 0 : _a.userPrincipalName, (_b = slot.tid) === null || _b === void 0 ? void 0 : _b.taskId);
    }, [app.user]);
    useEffect(() => {
        let interval;
        if (!isActive)
            return clearInterval(interval);
        interval = setInterval(() => {
            setTimeRemaining(endTime.diff(dateTimeUtils(undefined), 'seconds'));
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [isActive]);
    useEffect(() => {
        if (timeRemaining === 0) {
            showNotification();
            setIsOpen(false);
            setIsActive(false);
        }
    }, [timeRemaining]);
    const showNotification = () => {
        var _a, _b;
        workedTime += PERIOD;
        StorageUtils.saveWorkedTimeToStorage(workedTime, (_a = app.user) === null || _a === void 0 ? void 0 : _a.userPrincipalName, (_b = slot.tid) === null || _b === void 0 ? void 0 : _b.taskId);
        if (Notification.permission === 'granted') {
            new Notification('30-minute countdown is up!', { body: slot.title });
        }
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    new Notification('30-minute countdown is up!');
                }
            });
        }
    };
    const toggleTimer = () => {
        setIsOpen((prevState) => !prevState);
    };
    const toggle = () => {
        setIsActive(!isActive);
    };
    return (React.createElement("div", { style: { opacity: 0.90, backgroundColor: '#000', color: '#fff', width: '100%', height: '100%',
            position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
        } },
        React.createElement("div", null,
            React.createElement("div", null,
                React.createElement("h2", null,
                    "Time Remaining: ",
                    Math.floor(timeRemaining / 60),
                    ":",
                    (timeRemaining % 60).toString().padStart(2, '0')),
                React.createElement("h2", null,
                    "End Time: ",
                    endTime.format('HH:mm:SS'))),
            React.createElement(Button, { onClick: toggle }, isActive ? 'Pause' : 'Resume'),
            React.createElement(Button, { onClick: () => {
                    var _a, _b;
                    workedTime += PERIOD - timeRemaining;
                    StorageUtils.saveWorkedTimeToStorage(workedTime, (_a = app.user) === null || _a === void 0 ? void 0 : _a.userPrincipalName, (_b = slot.tid) === null || _b === void 0 ? void 0 : _b.taskId);
                    handleClose();
                } }, "Close"))));
}
export default CountdownTimer;
