import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, MenuItem, Switch, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WeightIn } from './WeightIn';
import { WeightInsTable } from './WeightInsTable';
import { enterWeightIn, mergeWeightIns } from "./weightSlice";
import WeightInsChart from './Chart';
import DataProcessor from './DataProcessor';
import { Tiles } from "./Tiles";
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import { toggleKgLbSetting, Units } from './settingsSlice';
import AddIcon from '@mui/icons-material/Add';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
const _baram = "boushar.baram";
export var FilterEnum;
(function (FilterEnum) {
    FilterEnum[FilterEnum["YEAR"] = 0] = "YEAR";
    FilterEnum[FilterEnum["NONE"] = 1] = "NONE";
    FilterEnum[FilterEnum["THIRTY"] = 2] = "THIRTY";
    FilterEnum[FilterEnum["SEVEN"] = 3] = "SEVEN";
})(FilterEnum || (FilterEnum = {}));
export const Weight = () => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [bDialogOpen, setDialogOpen] = useState(false);
    const [bSyncDialog, setSyncDialog] = useState(false);
    const weightInRef = useRef(null);
    const syncRef = useRef(null);
    const dateRef = useRef(null);
    const [secretKey, setSecretKey] = useState(() => localStorage.getItem(_baram));
    const [filter, setFilter] = useState(FilterEnum.THIRTY);
    const weightins = useSelector((state) => state.weightins);
    const { units } = useSelector((state) => state.settings);
    const augmentedWeightIns = DataProcessor.createDataProcessor().augment(weightins, filter, units);
    useEffect(() => {
        if (secretKey != undefined) {
            fetch("api/weightins", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "X-BOUSHAR-BARAM": secretKey
                }
            }).then(res => res.json().then(data => dispatch(mergeWeightIns(data))))
                .catch(err => console.error(err));
        }
    }, [secretKey]);
    const saveWeightIn = () => {
        var _a;
        let date;
        if (dateRef.current != null) {
            date = dateRef.current.value;
        }
        let value = (_a = weightInRef.current) === null || _a === void 0 ? void 0 : _a.valueAsNumber;
        if (value == undefined)
            throw (`Wrong input: value cannot be undefined`);
        if (date == undefined)
            throw ('Wrong input: date cannot be undefined');
        if (units == Units.KG) {
            value = value / 0.45359237;
        }
        const w = new WeightIn(date, value);
        dispatch(enterWeightIn({ weightin: w.toObject(), secretKey }));
        setDialogOpen(false);
    };
    const saveSecret = () => {
        setSyncDialog(false);
        if (syncRef.current != null) {
            setSecretKey(syncRef.current.value);
            localStorage.setItem(_baram, syncRef.current.value);
        }
    };
    const handleFilterClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleFilterClose = (f) => {
        setFilter(f);
        setAnchorEl(null);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { display: 'inline-grid', gridTemplateColumns: 'repeat(5,auto)', gap: '10px' } },
            React.createElement(IconButton, null,
                React.createElement(AddIcon, { onClick: () => setDialogOpen(true) })),
            React.createElement(IconButton, { onClick: () => setSyncDialog(true) },
                React.createElement(CloudSyncIcon, null)),
            React.createElement(IconButton, { onClick: handleFilterClick },
                React.createElement(FilterListIcon, null)),
            React.createElement(IconButton, null,
                React.createElement(CompareArrowsIcon, null)),
            React.createElement("div", null,
                React.createElement(Switch, { title: "switch between lb and kg", defaultChecked: units == Units.KG, onChange: () => dispatch(toggleKgLbSetting()) }))),
        React.createElement(Menu, { open: open, anchorEl: anchorEl, id: "basic-menu" },
            React.createElement(MenuItem, { onClick: () => handleFilterClose(FilterEnum.SEVEN) }, "7 Days"),
            React.createElement(MenuItem, { onClick: () => handleFilterClose(FilterEnum.THIRTY) }, "30 Days"),
            React.createElement(MenuItem, { onClick: () => handleFilterClose(FilterEnum.YEAR) }, "Year"),
            React.createElement(MenuItem, { onClick: () => handleFilterClose(FilterEnum.NONE) }, "All")),
        React.createElement("div", { style: { paddingTop: '10px' } }),
        React.createElement(Tiles, { weightIns: augmentedWeightIns }),
        React.createElement(WeightInsChart, { augmentedWeightIns: augmentedWeightIns, _weightins: [], _futureWeightIns: [] }),
        React.createElement(WeightInsTable, { weightIns: augmentedWeightIns }),
        React.createElement(Dialog, { open: bDialogOpen },
            React.createElement(DialogTitle, null,
                "Save/Edit Entry in ",
                units == Units.KG ? 'Kilograms' : 'Pounds'),
            React.createElement(DialogContent, null,
                React.createElement(TextField, { id: "eidWeight", label: "Weight", type: "number", inputProps: { step: "0.1" }, inputRef: weightInRef }),
                React.createElement(TextField, { id: "eidDate", label: "Date", type: "date", inputRef: dateRef })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { variant: "contained", color: "primary", onClick: saveWeightIn }, "Save"),
                React.createElement(Button, { variant: "contained", color: "primary", onClick: () => setDialogOpen(false) }, "Cancel"))),
        React.createElement(Dialog, { open: bSyncDialog },
            React.createElement(TextField, { type: "text", inputRef: syncRef, defaultValue: secretKey }),
            React.createElement(Button, { color: "primary", onClick: saveSecret }, "Save"))));
};
