import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { clearSlot } from "../../features/slots/slotsSlice";
import { useAppContext } from "../../hooks/AppContext";
import { updateBucket } from "../../services/GraphService/GraphService";
export const BucketView = ({ slot }) => {
    const dispatch = useDispatch();
    const app = useAppContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const bOpen = Boolean(anchorEl);
    let name;
    let menuItemClass1;
    let menuItemClass2;
    let b1;
    let b2;
    switch (slot.name) {
        case "slot1":
            name = 'bucket1';
            menuItemClass1 = 'bucket3';
            menuItemClass2 = 'bucket5';
            b1 = 'three';
            b2 = 'five';
            break;
        case "slot31":
        case "slot32":
        case "slot33":
            name = 'bucket3';
            menuItemClass1 = 'bucket1';
            menuItemClass2 = 'bucket5';
            b1 = 'one';
            b2 = 'five';
            break;
        case "slot51":
        case "slot52":
        case "slot53":
        case "slot54":
        case "slot55":
            name = "bucket5";
            menuItemClass1 = 'bucket1';
            menuItemClass2 = 'bucket3';
            b1 = 'one';
            b2 = 'three';
            break;
    }
    const handleClick = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };
    const handleClose = (e) => {
        e.stopPropagation();
        setAnchorEl(null);
    };
    const handleSelect = (e, bucket) => {
        e.stopPropagation();
        handleClose(e);
        updateBucket(slot, bucket, app.authProvider);
        dispatch(clearSlot(slot.getIndex()));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: name, onClick: handleClick }),
        React.createElement(Menu, { open: bOpen, onClose: handleClose, anchorEl: anchorEl },
            React.createElement(MenuItem, null,
                React.createElement("div", { className: menuItemClass1, onClick: (e) => handleSelect(e, b1) })),
            React.createElement(MenuItem, null,
                React.createElement("div", { className: menuItemClass2, onClick: (e) => handleSelect(e, b2) })))));
};
