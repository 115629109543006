import React, { useEffect, useState } from "react";
import { Events, subscribe } from "../utils/Belt";
import './BusySignal.scss';
export default function BusySignal() {
    const [bShowSignal, setShowSignal] = useState(false);
    useEffect(() => {
        subscribe(Events.UPDATING_ALL_SLOTS, () => {
            setShowSignal(true);
        });
        subscribe(Events.UPDATING_ALL_SLOTS_DONE, () => {
            setShowSignal(false);
        });
    }, []);
    return (React.createElement(React.Fragment, null, bShowSignal && React.createElement("div", { className: "BusySignal" },
        React.createElement("svg", { width: "25px", height: "25px" },
            React.createElement("circle", { id: "orbit1", cx: "50%", cy: "50%", r: "10px", fill: "none", stroke: "#ffffff", strokeWidth: "0.7" }),
            React.createElement("circle", { id: "orbit2", cx: "50%", cy: "50%", r: "4px", fill: "none", stroke: "#ffffff", strokeWidth: "0.7" }),
            React.createElement("circle", { id: "mars", cx: "50%", cy: "50%", r: "3px", fill: "#69b3b2" }),
            React.createElement("circle", { id: "earth", cx: "50%", cy: "50%", r: "2.5px", fill: "#f099f1" })))));
}
