import { GraphClient } from "./GraphClient";
import { GraphClientFactory } from "./GraphClientFactory";
export class UpdateGraphClient extends GraphClient {
    updateBucket(slot, bucket) {
        const client = GraphClientFactory.makeUpdateClient(this.authProvider, slot);
        const updateTask = {
            categories: [bucket]
        };
        const response = client.update(updateTask);
        return response;
    }
}
