import { useTheme } from "@mui/material";
import moment from "moment";
import "moment-timezone";
import * as React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((_theme) => ({
    border: {
        borderBottom: "1px solid white"
    },
    clock: {
        fontSize: '1em',
        paddingLeft: '10px'
    }
}));
const LOS_ANGELES = "America/Los_Angeles";
const BEIRUT = "Asia/Beirut";
export const Clock = () => {
    const classes = useStyles(useTheme());
    const time = useSelector((state) => state.clock);
    const nowMoment = moment(time);
    const offsetMoment = (moment().add(moment().date(), 'hours'));
    const currentTimeZone = moment.tz.guess();
    return (React.createElement("div", { style: { display: "flex" }, className: classes.clock },
        React.createElement("div", null,
            React.createElement("div", { className: classes.border }, nowMoment.format("h:mm A")),
            React.createElement("div", null, nowMoment.format("MMM D"))),
        (currentTimeZone != BEIRUT) && React.createElement("div", { style: { marginLeft: "20px" } },
            React.createElement("div", { className: classes.border }, nowMoment.tz("Asia/Beirut").format("h:mm A")),
            React.createElement("div", null, "BEY")),
        (currentTimeZone != LOS_ANGELES) && React.createElement("div", { style: { marginLeft: "20px" } },
            React.createElement("div", { className: classes.border }, nowMoment.tz(LOS_ANGELES).format("h:mm A")),
            React.createElement("div", null, "SFO")),
        React.createElement("div", { style: { marginLeft: "20px" } },
            React.createElement("div", { className: classes.border }, nowMoment.tz("America/New_York").format("h:mm A")),
            React.createElement("div", null, "BOS"))));
};
