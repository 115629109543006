export var Events;
(function (Events) {
    Events["ADDTASK"] = "ADDTASK";
    Events["DATA_READY"] = "DATA_READY";
    Events["UPDATE_APP"] = "UPDATE_APP";
    Events["DEBUG"] = "DEBUG";
    Events["MSGraphError"] = "MSGraphError";
    Events["UPDATING_ALL_SLOTS"] = "UPDATING_ALL_SLOTS";
    Events["UPDATING_ALL_SLOTS_DONE"] = "UPDATING_ALL_SLOTS_DONE";
})(Events || (Events = {}));
class Belt {
    constructor() {
        let $div = document.createElement("div");
        $div.setAttribute("id", "_boushar_belt");
        document.body.appendChild($div);
    }
    static getInstance() {
        if (!Belt.instance) {
            Belt.instance = new Belt();
        }
        return Belt.instance;
    }
    publish(type, data) {
        var _a;
        const event = new CustomEvent(type, { detail: data });
        (_a = document.getElementById("_boushar_belt")) === null || _a === void 0 ? void 0 : _a.dispatchEvent(event);
    }
    subscribe(type, callback) {
        var _a;
        (_a = document.getElementById("_boushar_belt")) === null || _a === void 0 ? void 0 : _a.addEventListener(type, callback);
    }
    unsubscribe(type, callback) {
        var _a;
        (_a = document.getElementById("_boushar_belt")) === null || _a === void 0 ? void 0 : _a.removeEventListener(type, callback, false);
    }
}
export const publish = (type, data) => {
    Belt.getInstance().publish(type, data);
};
export const subscribe = (type, callback) => {
    Belt.getInstance().subscribe(type, callback);
};
