import './pwa';
import * as React from "react";
import App from './components/App';
import config from './Config';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { PublicClientApplication } from "@azure/msal-browser";
import "./assets/app.scss";
import { MsalProvider } from '@azure/msal-react';
const pca = new PublicClientApplication({
    auth: {
        clientId: config.appId,
        authority: "https://login.microsoftonline.com/consumers"
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
});
await pca.initialize();
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(React.createElement(React.StrictMode, null,
    React.createElement(Provider, { store: store },
        React.createElement(MsalProvider, { instance: pca },
            React.createElement(App, null)))));
