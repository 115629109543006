import { createSlice } from "@reduxjs/toolkit";
import { Slot } from "./Slot";
import { SlotsBoard } from "./SlotsBoard";
const slotsSlice = createSlice({
    name: "slots",
    initialState: SlotsBoard.createDefaultSlotsBoard().object(),
    reducers: {
        addTaskToSlotAction(state, action) {
            const board = SlotsBoard.createSlotsBoard(state);
            board.addTaskToSlot(action.payload);
        },
        clearSlot(state, action) {
            const board = SlotsBoard.createSlotsBoard(state);
            board.clearSlot(action.payload);
            state.list = JSON.parse(JSON.stringify(board.list));
            state.updated = (new Date()).toISOString();
            return state;
        },
        emptySlot(state, action) {
            const board = SlotsBoard.createSlotsBoard(state);
            board.updateSlotWithTaskId(action.payload, null, "");
            return board;
        },
        fillSlot(state, action) {
            const board = SlotsBoard.createSlotsBoard(state);
            board.updateSlotWithTodo(action.payload.slot, action.payload.todo);
            return board;
        },
        mergeSlots(state, action) {
            const sb = SlotsBoard.createSlotsBoard(state);
            if (action.payload !== undefined) {
                sb.merge(action.payload);
            }
            return sb;
        },
        refreshBoard(state, action) {
            state.updated = (new Date()).toISOString();
        },
        updateSlot(state, action) {
            const sb = SlotsBoard.createSlotsBoard(state);
            const { slot, todo } = action.payload;
            const s = new Slot(slot);
            sb.updateSlotWithTodo(s, todo);
            state.list = sb.list;
            state.updated = (new Date()).toISOString();
        },
        clearSlots(state, action) {
            return Object.assign({}, SlotsBoard.createDefaultSlotsBoard().object());
        },
        replaceSlots(state, action) {
            state.list = action.payload.list;
            state.updated = action.payload.updated;
        },
        updateSlots(state, action) {
            action.payload.updated = (new Date()).toISOString();
            return Object.assign({}, action.payload);
        },
        replaceSlot(state, action) {
            const sb = SlotsBoard.createSlotsBoard(state);
            const list = sb.getSlots().map((s) => {
                if (s.getName() === action.payload.name) {
                    return Slot.copySlot(action.payload);
                }
                else {
                    return s;
                }
            });
            sb.list = list;
            state = sb.object();
            state.updated = (new Date()).toISOString();
            return state;
        }
    }
});
export const { addTaskToSlotAction, clearSlot, clearSlots, fillSlot, mergeSlots, refreshBoard, replaceSlot, replaceSlots, updateSlot, updateSlots, } = slotsSlice.actions;
export default slotsSlice.reducer;
