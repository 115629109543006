import * as React from "react";
import { Slots } from "../../../onethreefive/components/Slots";
import { useAppContext } from "../../../hooks/AppContext";
import { getTodos } from "../../../services/GraphService/GraphService";
export const AppComponent = () => {
    const app = useAppContext();
    React.useEffect(() => {
        const loadTodos = async () => {
            await getTodos(app.authProvider);
        };
        loadTodos();
    }, []);
    return (React.createElement(Slots, null));
};
const TabPanel = (props) => {
    const { children, value, index } = props;
    return React.createElement("div", { role: "tabpanel" }, value === index && React.createElement("div", null, children));
};
