if ('serviceWorker' in navigator) {
    window.addEventListener('load', async () => {
        try {
            const reg = await navigator.serviceWorker.register('/microsoft-graph-sw.js');
            if (reg.installing) {
                console.log('Service worker installing');
            }
            else if (reg.waiting) {
                console.log('Service worker installed');
            }
            else if (reg.active) {
                console.log('Service workder active');
            }
        }
        catch (error) {
            console.error(`Registration failed with ${error}`);
        }
        const registration = await navigator.serviceWorker.register('/service-worker.js');
    });
}
export {};
