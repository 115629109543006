import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import dateTimeUtils from '../utils/DateTimeUtils';
import Logger from '../utils/Logger';
import { version, commit } from '../utils/version';
import Button from '@mui/material/Button';
export const About = () => {
    const [messages, setMessages] = React.useState([]);
    const getMessages = async () => {
        try {
            const messages = await Logger.getAllLogs();
            setMessages(messages);
        }
        catch (err) {
            console.error(err);
        }
    };
    getMessages();
    const rows = messages.sort((a, b) => (b.created - a.created));
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            "Version: ",
            version),
        React.createElement("div", { style: { color: "grey", fontSize: "0.55em" } }, commit),
        React.createElement(Button, { onClick: clear }, "Clear"),
        React.createElement(TableContainer, { component: Paper },
            React.createElement(Table, { sx: { minWidth: 650 }, size: "small", "aria-label": "log-table" },
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, "Date"),
                        React.createElement(TableCell, null, "Message"))),
                React.createElement(TableBody, null, rows.map((row) => (React.createElement(TableRow, { sx: { "& td": { fontSize: "0.6em" } } },
                    React.createElement(TableCell, { sx: { fontSize: "0.75em" } }, dateTimeUtils(row.created).format("YYYY-M-D, h:mm:ss a")),
                    React.createElement(TableCell, null, row.msg.toString())))))))));
};
function clear() {
    Logger.clear();
}
