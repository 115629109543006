import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import StateManager from "./StateManager";
import { IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
export default function Categories({ categories, setCategories }) {
    const [open, setOpen] = useState(false);
    const [category, setCategory] = useState("");
    //     ^?
    useEffect(() => {
        const categories = StateManager.getCategories();
        setCategories(categories);
    }, []);
    useEffect(() => {
        const handleUpdateCategoriesEvent = (data) => {
            setCategories([...StateManager.getCategories()]);
        };
        window.addEventListener('CATEGORIES_UPDATED', handleUpdateCategoriesEvent);
        return () => { window.removeEventListener('CATEGORIES_UPDATED', handleUpdateCategoriesEvent, false); };
    }, []);
    const handleClose = () => {
        setOpen(false);
    };
    const handleBlurCategory = (event) => {
        setCategory(event.target.value);
    };
    const handleAddCategory = () => {
        categories.push({ name: category, count: 0, color: '#eeeeee' });
        setCategories([...categories]);
        setOpen(false);
        StateManager.saveCategories(categories);
    };
    const handleDelete = (category) => {
        const i = categories.findIndex((c) => c.name == category.name);
        if (i > -1) {
            categories.splice(i, 1);
            setCategories([...categories]);
            StateManager.saveCategories(categories);
        }
    };
    const list = categories === null || categories === void 0 ? void 0 : categories.map((c) => {
        return (React.createElement(ListItem, { secondaryAction: React.createElement(IconButton, { edge: "end", "aria-label": "delete" },
                React.createElement(DeleteIcon, { onClick: () => handleDelete(c) })) },
            React.createElement(ListItemAvatar, null,
                React.createElement("span", { style: { color: c.color, fontSize: '2em' } }, "\u25A0")),
            React.createElement(ListItemText, { primary: c.count + " " + c.name })));
    });
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("br", null),
            React.createElement(Button, { variant: "outlined", title: "Manage Categories", onClick: () => setOpen(true) }, "Add")),
        React.createElement(List, { dense: true }, list),
        React.createElement(Dialog, { open: open, onClose: handleClose },
            React.createElement(DialogTitle, null, "Add Category"),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null, "Add New Category"),
                React.createElement(TextField, { autoFocus: true, margin: "dense", id: "name", label: "category", fullWidth: true, variant: "standard", onBlur: handleBlurCategory })),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleClose }, "Cancel"),
                React.createElement(Button, { onClick: handleAddCategory }, "Add")))));
}
