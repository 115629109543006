import React from "react";
const ZeroWeightIn = { weight: 0, sevenDaysAverage: 0, thirtyDaysAverage: 0 };
export const Tiles = ({ weightIns }) => {
    const listWeightIn = Object.values(weightIns).sort((a, b) => {
        if (a.date < b.date)
            return -1;
        else
            return 1;
    });
    if (listWeightIn.length === 0) {
        return (React.createElement(React.Fragment, null));
    }
    const lastWeightIn = listWeightIn.pop() || { weight: 0, sevenDaysAverage: 0, thirtyDaysAverage: 0 };
    const beforeLast = listWeightIn.pop() || { weight: 0, sevenDaysAverage: 0, thirtyDaysAverage: 0 };
    // try to find the 7th weight in from last position
    let last7thWeightIn = ZeroWeightIn;
    if (listWeightIn.length > 5) {
        last7thWeightIn = listWeightIn[listWeightIn.length - 5];
    }
    // try to find the 30th weight in from last position
    let last30thWeightIn = ZeroWeightIn;
    if (listWeightIn.length > 28) {
        last30thWeightIn = listWeightIn[listWeightIn.length - 28];
    }
    const color = '#680d0d';
    const negativeColor = '#6246ea';
    let weightColor = color, sevenColor = color, thirtyColor = color;
    weightColor = (lastWeightIn.weight - beforeLast.weight < 0) ? negativeColor : color;
    sevenColor = (lastWeightIn.sevenDaysAverage - beforeLast.sevenDaysAverage < 0) ? negativeColor : color;
    thirtyColor = (lastWeightIn.thirtyDaysAverage - beforeLast.thirtyDaysAverage < 0) ? negativeColor : color;
    return (React.createElement("div", { style: { display: 'flex', flexDirection: 'row', justifyContent: 'space-around' } },
        React.createElement("div", { style: { fontFamily: "sans-serif", backgroundColor: weightColor, color: "#fffffe", borderRadius: "10px", padding: "10px" } },
            React.createElement("div", null, "Weight"),
            React.createElement("div", null, lastWeightIn.weight.toFixed(1)),
            React.createElement("div", { style: { fontSize: '0.75em' } }, (lastWeightIn.weight - beforeLast.weight).toFixed(1))),
        React.createElement("div", { style: { fontFamily: "sans-serif", backgroundColor: sevenColor, color: "#fffffe", borderRadius: "10px", padding: "10px" } },
            React.createElement("div", null, "7-Average"),
            React.createElement("div", null, lastWeightIn.sevenDaysAverage.toFixed(1)),
            React.createElement("div", { style: { display: 'grid', gridTemplateColumns: 'repeat(3, auto)', fontSize: '0.75em' } },
                React.createElement("div", null, (lastWeightIn.sevenDaysAverage - beforeLast.sevenDaysAverage).toFixed(1)),
                React.createElement("div", null,
                    "\u00A0",
                    (lastWeightIn.sevenDaysAverage - last7thWeightIn.sevenDaysAverage).toFixed(1)),
                React.createElement("div", null,
                    "\u00A0",
                    (((lastWeightIn.sevenDaysAverage - last7thWeightIn.sevenDaysAverage) / last7thWeightIn.sevenDaysAverage) * 100).toFixed(2),
                    "%"))),
        React.createElement("div", { style: { fontFamily: "sans-serif", backgroundColor: thirtyColor, color: "#fffffe", borderRadius: "10px", padding: "10px" } },
            React.createElement("div", null, "30-Average"),
            React.createElement("div", null, lastWeightIn.thirtyDaysAverage.toFixed(1)),
            React.createElement("div", { style: { display: 'grid', gridTemplateColumns: 'repeat(3, auto)', fontSize: '0.75em' } },
                React.createElement("div", null, (lastWeightIn.thirtyDaysAverage - beforeLast.thirtyDaysAverage).toFixed(1)),
                React.createElement("div", null,
                    "\u00A0",
                    (lastWeightIn.thirtyDaysAverage - last30thWeightIn.thirtyDaysAverage).toFixed(1)),
                React.createElement("div", null,
                    "\u00A0",
                    (((lastWeightIn.thirtyDaysAverage - last30thWeightIn.thirtyDaysAverage) / last30thWeightIn.thirtyDaysAverage) * 100).toFixed(2),
                    "%")))));
};
