import * as React from "react";
import { clone } from 'lodash';
import * as Belt from './Belt';
import ChartJS from 'chart.js/auto';
import { useRef } from "react";
const chartOptions = {
    title: {
        text: "Something new",
    },
    scales: {
        xAxes: [{
                type: 'time',
                //distribution: 'series',
                time: {
                    unit: 'day'
                }
            }
        ]
    }
};
const avgColor = "rgba( 99 ,184, 180)";
const weightColor = "rgb( 39, 93, 98)";
const sevenAvgColor = "rgb(220 ,126, 45)";
let chartData = {
    labels: [],
    datasets: [
        {
            label: "weight",
            backgroundColor: weightColor,
            borderColor: weightColor,
            pointRadius: 1,
            pointHitRadius: 20,
            fill: false,
            data: []
        },
        /*{
          label: "future",
          backgroundColor: futureColor,
          borderColor: futureColor,
          pointRadius: 1,
          pointHitRadius: 20,
          fill: false,
          data: []
        },
        {
          label: "avg",
          backgroundColor: avgColor,
          borderColor: avgColor,
          pointRadius: 1,
          pointHitRadius: 20,
          fill: false,
          data: []
        },*/
        {
            label: "7-avg",
            backgroundColor: sevenAvgColor,
            borderColor: sevenAvgColor,
            fill: false,
            pointRadius: 0,
            data: []
        },
        {
            label: "30-avg",
            backgroundColor: avgColor,
            borderColor: avgColor,
            fill: false,
            pointRadius: 0,
            data: []
        }
    ]
};
let chart;
const WeightInsChart = (props) => {
    const canvasRef = useRef(null);
    const [w, setWidth] = React.useState(window.innerWidth);
    const [h, setHeight] = React.useState(window.innerHeight - 100);
    let labels = Array();
    let dataset1Data = Array();
    let dataset2Data = Array();
    let dataset3Data = Array();
    const { augmentedWeightIns } = props;
    let keys = Object.keys(augmentedWeightIns);
    // if (props.filter === FilterEnum.MONTH) {
    //   // get today month and year
    //   const day = waet().getDayOfTheMonth()
    //   const year = waet().getYear()
    //   for (let i = 0; i < keys.length; ++i) {
    //     if (waet(keys[i]).getDayOfTheMonth() != day || waet(keys[i]).getYear() != year) {
    //       delete augmentedWeightIns[keys[i]]
    //     }
    //   }
    //   keys = Object.keys(augmentedWeightIns);
    // } else if (props.filter === FilterEnum.YEAR) {
    //   const year = waet().getYear()
    //   for (let i = 0; i < keys.length; ++i) {
    //     if (waet(keys[i]).getYear() != year) {
    //       delete augmentedWeightIns[keys[i]]
    //     }
    //   }
    //   keys = Object.keys(augmentedWeightIns);
    // }
    keys.sort();
    for (let i = 0; i < keys.length; ++i) {
        labels.push(keys[i]);
        dataset1Data.push(augmentedWeightIns[keys[i]].weight);
        dataset2Data.push(augmentedWeightIns[keys[i]].sevenDaysAverage);
        dataset3Data.push(augmentedWeightIns[keys[i]].thirtyDaysAverage);
    }
    chartData.labels = labels;
    chartData.datasets[0].data = dataset1Data;
    chartData.datasets[1].data = dataset2Data;
    chartData.datasets[2].data = dataset3Data;
    // chartData.datasets[3].data = dataset3Data;
    React.useEffect(() => {
        const $canvas = canvasRef.current;
        if (chart == null) {
            if ($canvas == null)
                return;
            chart = new ChartJS($canvas.getContext('2d'), {
                type: 'line',
                data: chartData,
                options: {}
            });
        }
        else {
            chart.update();
        }
        const updateDataset = (data) => {
            chartData.datasets[4].data = clone(data);
        };
        Belt.subscribe(Belt.Events.DATA_READY, updateDataset);
        return () => {
            Belt.unsubscribe(Belt.Events.DATA_READY, updateDataset);
        };
    }, [chartData.datasets[0].data, chartData.datasets[1].data, w, h]);
    React.useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    return (React.createElement("canvas", { ref: canvasRef, width: w, height: h }));
};
export default WeightInsChart;
