import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
export default function () {
    return (React.createElement(Box, { display: "grid", gridTemplateColumns: "auto auto auto", style: { alignItems: 'center' } },
        React.createElement(Box, null, "Fasting"),
        React.createElement(Box, null,
            React.createElement(OutlinedInput, { placeholder: "Hours" })),
        React.createElement(Box, null,
            React.createElement(Box, null,
                React.createElement(ArrowDropUpIcon, null)),
            React.createElement(Box, null,
                React.createElement(ArrowDropDownIcon, null))),
        React.createElement(Box, null, "Credit"),
        React.createElement(Box, null,
            React.createElement(OutlinedInput, { placeholder: "Hours" })),
        React.createElement(Box, null,
            React.createElement(Box, null,
                React.createElement(ArrowDropUpIcon, null)),
            React.createElement(Box, null,
                React.createElement(ArrowDropDownIcon, null))),
        React.createElement(Box, null, "Croissant"),
        React.createElement(Box, null,
            React.createElement(OutlinedInput, { placeholder: "Hours" })),
        React.createElement(Box, null,
            React.createElement(Box, null,
                React.createElement(ArrowDropUpIcon, null)),
            React.createElement(Box, null,
                React.createElement(ArrowDropDownIcon, null)))));
}
