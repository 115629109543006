import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlotsBoard } from "../../features/slots/SlotsBoard";
import { updateSlots, replaceSlots } from "../../features/slots/slotsSlice";
import { useAppContext } from "../../hooks/AppContext";
import { addTask, getCompletedTasks, getNotStartedTasks } from "../../services/GraphService/GraphService";
import { Progress } from "../../components/Progress";
import { SlotView } from './SlotView';
import moment from "moment";
import dateTimeUtils from "../../utils/DateTimeUtils";
import { fibonacci } from "../../utils";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import UpdateIcon from '@mui/icons-material/Update';
import UpdateDisabledIcon from '@mui/icons-material/UpdateDisabled';
import Checkbox from '@mui/material/Checkbox';
import { SlotsProcessor } from "../processors/SlotsProcessor";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { Events, subscribe, publish } from "../../utils/Belt";
import OutlinedInput from "@mui/material/OutlinedInput";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTheme } from '@mui/material/styles';
function findTodoNotOnBoard(todos, slots) {
    if (todos == null)
        return null;
    for (let i = 0; i < todos.length; i++) {
        if (slots.isTodoOnBoard(todos[i]))
            continue;
        return todos[i];
    }
    return null;
}
import { Plan } from "./Plan";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { DialogActions, DialogContentText, Snackbar, useMediaQuery } from "@mui/material";
import { ProgressOverlay } from "../../components/ProgressOverlay";
let s_bUpdateAllSlotsPending = false;
export const Slots = () => {
    var _a;
    const [notificationTimeout, setNotificationTimeout] = useState(moment());
    const [bProgressOverlayOpen, setProgressOverlayOpen] = useState(false);
    const dispatch = useDispatch();
    const app = useAppContext();
    const s = useSelector((state) => (state.slots));
    const slots = SlotsBoard.createSlotsBoardFromJson(s);
    const [counter, setCounter] = useState(0);
    const [numberOfCompletedTasks, setNumberOfCompletedTasks] = useState(0);
    const [level, setLevel] = useState(1);
    const [upperBound, setUpperBound] = useState(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const [updateChecked, setUpdateChecked] = useState(true);
    const [alertOpen, setAlertOpen] = useState(false);
    const [errorDetails, setErrorDetails] = useState();
    const bLargeFormFactor = useMediaQuery(useTheme().breakpoints.up('sm'));
    const [bOpenSnack, setOpenSnack] = useState(false);
    const [snackTitle, setSnackTitle] = useState('');
    const [updateSeconds, _] = useState(1 * 60); // number of seconds to vary between 1 and 60
    const handleUpdateChange = (event) => {
        setUpdateChecked(event.target.checked);
    };
    React.useEffect(() => {
        subscribe(Events.MSGraphError, (data) => {
            setUpdateChecked(false);
            setAlertOpen(true);
            setErrorDetails(data.detail);
            //alert(data.detail)
        });
    }, []);
    React.useEffect(() => {
        for (let i = 0; i < 100; ++i) {
            let upperBound = fibonacci(i);
            if (upperBound > numberOfCompletedTasks) {
                setLevel(i);
                setUpperBound(upperBound);
                break;
            }
        }
    }, [numberOfCompletedTasks]);
    // read the state once
    React.useEffect(() => {
        var _a, _b;
        if (((_a = app.user) === null || _a === void 0 ? void 0 : _a.userPrincipalName) != null) {
            const slotsJSON = localStorage.getItem(((_b = app.user) === null || _b === void 0 ? void 0 : _b.userPrincipalName) + '.slots');
            if (slotsJSON != null) {
                dispatch(replaceSlots(JSON.parse(slotsJSON)));
            }
            setIsInitialized(true);
        }
    }, [(_a = app.user) === null || _a === void 0 ? void 0 : _a.userPrincipalName]);
    React.useEffect(() => {
        const id = setInterval(() => {
            if (updateChecked) {
                setCounter(counter => counter + 1);
            }
        }, updateSeconds * 1000);
        return () => { clearInterval(id); };
    }, [updateChecked]);
    React.useEffect(() => {
        var _a, _b;
        if (((_a = app.user) === null || _a === void 0 ? void 0 : _a.userPrincipalName) != null) {
            localStorage.setItem(((_b = app.user) === null || _b === void 0 ? void 0 : _b.userPrincipalName) + '.slots', JSON.stringify(slots.object()));
        }
    }, [slots.updated]);
    React.useEffect(() => {
        const updateAllSlots = async () => {
            s_bUpdateAllSlotsPending = true;
            publish(Events.UPDATING_ALL_SLOTS, {});
            try {
                const todos = await getNotStartedTasks(app.authProvider);
                await SlotsProcessor.process(dispatch, app.authProvider, slots, todos);
            }
            finally {
                s_bUpdateAllSlotsPending = false;
                publish(Events.UPDATING_ALL_SLOTS_DONE, {});
            }
        };
        const notify = async () => {
            if (dateTimeUtils(notificationTimeout).isAfterNow()) {
                return;
            }
            for (let i = 0; i < slots.getSlots().length; ++i) {
                const s = slots.getSlots()[i];
                if (s.isEmpty()) {
                    continue;
                }
                if (s.isComplete()) {
                    continue;
                }
                if (s.status === 'postponed') {
                    continue;
                }
                if (dateTimeUtils(notificationTimeout).isBeforeNow()) {
                    let msg = s.title;
                    if (s.headerTitle != null) {
                        msg = `${s.headerTitle}:${s.title}`;
                    }
                    notifyTask(msg);
                    notificationTimeout.second(0);
                    notificationTimeout.minutes(0);
                    notificationTimeout.millisecond(0);
                    notificationTimeout.add(1, "h");
                    setNotificationTimeout(notificationTimeout);
                    break;
                }
            }
        };
        if (isInitialized) {
            if (!s_bUpdateAllSlotsPending) {
                updateAllSlots();
                getCompletedTasks(app.authProvider).then((c) => setNumberOfCompletedTasks(c.length));
            }
            notify();
        }
    }, [counter]);
    const views = slots.getSlots().map((slot, index) => {
        return (React.createElement(SlotView, { slot: slot, slots: slots, index: index, key: slot.getName() }));
    });
    let bSmallFormFactor = false;
    if (window.innerWidth < 700) {
        bSmallFormFactor = true;
    }
    // to get username use: app.user?.userPrincipalName
    const clearBoard = () => {
        slots.clearSlots();
        dispatch(updateSlots(slots.object()));
    };
    const addTaskRef = React.useRef();
    const onAddTask = () => {
        try {
            setProgressOverlayOpen(true);
            if (addTaskRef.current != null) {
                addTask(addTaskRef.current.value, app.authProvider).then(res => {
                    setSnackTitle(`Added: ${res.title}`);
                    setOpenSnack(true);
                });
                addTaskRef.current.value = "";
            }
        }
        finally {
            setProgressOverlayOpen(false);
        }
    };
    const handleTaskInputKeyUp = (event) => {
        if (event.key === 'Enter') {
            onAddTask();
        }
    };
    const handleAlertClose = () => {
        setAlertOpen(false);
    };
    const handleSnackClose = (_, reason) => {
        if (reason == 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Snackbar, { open: bOpenSnack, autoHideDuration: 6000, anchorOrigin: { vertical: 'top', horizontal: 'center' }, message: snackTitle, onClose: handleSnackClose }),
        React.createElement(ProgressOverlay, { open: bProgressOverlayOpen }),
        React.createElement("div", { style: { display: 'inline-flex', alignItems: 'center', gap: '1px' } },
            React.createElement(Progress, { level: level, total: upperBound, current: numberOfCompletedTasks }),
            React.createElement(Link, { href: 'https://to-do.live.com/tasks/inbox', target: "_blank" },
                React.createElement(IconButton, null,
                    React.createElement(OpenInNewIcon, null))),
            React.createElement(Checkbox, { checked: updateChecked, onChange: handleUpdateChange, icon: React.createElement(UpdateDisabledIcon, null), checkedIcon: React.createElement(UpdateIcon, null) }),
            React.createElement(IconButton, { onClick: clearBoard },
                React.createElement(ClearAllIcon, null))),
        React.createElement(Box, { display: "grid", gridTemplateColumns: "10fr 1fr", gap: 2 },
            React.createElement(OutlinedInput, { size: "small", placeholder: "Add Task", inputRef: addTaskRef, onKeyUp: handleTaskInputKeyUp }),
            React.createElement(Button, { variant: "outlined", onClick: onAddTask }, "Add")),
        React.createElement("div", { className: "grid-layout-one" },
            React.createElement("div", { className: "slot bg-one" }, views[0])),
        React.createElement("div", { className: "grid-layout-three" },
            React.createElement("div", { className: "slot bg-three" }, views[1]),
            React.createElement("div", { className: "slot bg-three" }, views[2]),
            React.createElement("div", { className: "slot bg-three" }, views[3])),
        React.createElement("div", { className: "grid-layout-five" },
            React.createElement("div", { className: "slot bg-five" }, views[4]),
            React.createElement("div", { className: "slot bg-five" }, views[5]),
            React.createElement("div", { className: "slot bg-five" }, views[6]),
            React.createElement("div", { className: "slot bg-five" }, views[7]),
            React.createElement("div", { className: "slot bg-five" }, views[8])),
        React.createElement("div", { style: { padding: '10px' } }),
        bLargeFormFactor && React.createElement(Plan, null),
        alertOpen && React.createElement(Dialog, { open: alertOpen },
            React.createElement(DialogTitle, null),
            React.createElement(DialogContent, null,
                React.createElement(DialogContentText, null,
                    errorDetails && errorDetails.message,
                    React.createElement("div", { style: { fontSize: '0.75em' } }, errorDetails === null || errorDetails === void 0 ? void 0 : errorDetails.stack))),
            React.createElement(DialogActions, null,
                React.createElement(Button, { onClick: handleAlertClose }, "Close")))));
};
const notifyTask = (msg) => {
    if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notifications");
    }
    else if (Notification.permission === "granted") {
        new Notification(msg);
    }
    else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(permission => {
            if (permission === "granted") {
                new Notification(msg);
            }
        });
    }
};
